<template>
    <LicenceWrapper :id="'documents_priorities'">
        <ideo-filter :name="name" :label="label" :display="filterDisplay" @clear="resetFilter">
            <div class="stars">
                <star-rating v-model="priorityValue"></star-rating>
            </div>
        </ideo-filter>
    </LicenceWrapper>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(defineProps<{
    priority?: number
    label?: string
    name: string
}>(), {
    priority: null,
    label: '',
    name: ''
});

defineOptions({
    name: 'document-priority-filter',
    inheritAttrs: false,
});

const emit = defineEmits<{
    (e: 'update:priority', value: number): void,
}>();


const priorityValue = computed({
    get()
    {
        return props.priority;
    },
    set(value: number)
    {
        emit('update:priority', value);
    }
});

const filterDisplay = computed<number>(() =>
{
    if (!props.priority)
        return null;

    return props.priority;
});

const resetFilter = (): void =>
{
    priorityValue.value = null;
};
</script>

<style lang="scss" scoped>
.stars {
    font-size: 2rem;

    :deep(.star-control) {
        justify-content: center;
    }
}
</style>
