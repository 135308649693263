import { getCurrentInstance, Plugin } from "vue";
import ModulesService, { DocumentCountModel } from "@/modules/wms/common/services/ModulesService";

export interface WmsModulesCounter
{
    get(): Promise<DocumentCountModel[]>;
    getForModule(module: string): Promise<DocumentCountModel[]>;
}

class WmsModuleCounterHelper implements WmsModulesCounter
{
    public async get(): Promise<DocumentCountModel[]>
    {
        const response = await ModulesService.getDocumentsCount();

        const items = response.result;

        return items;
    }

    public async getForModule(module: string): Promise<DocumentCountModel[]>
    {
        const response = await ModulesService.getDocumentsCountForModule(module);

        const items = response.result;

        return items;
    }
}

export const useWmsModulesCounter = () =>
{
    const app = getCurrentInstance();

    return {
        $wmsmodulescounter: app.appContext.config.globalProperties.$wmsmodulescounter
    };
};

const WmsModulesCounter: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$wmsmodulescounter = new WmsModuleCounterHelper();
    }
};

export default WmsModulesCounter;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $wmsmodulescounter: WmsModulesCounter;
    }
}
