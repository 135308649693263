import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Columns } from '@/helpers/Interfaces';
import { PagerContract } from '@/helpers/Pager';
import { FilterState } from '@/modules/core/common/services/FilterService';

export interface ViewStateStore
{
    key: string;
    getFilters(key: string): FilterState;
    setFilters(key: string, item: FilterState): void;
    getPager(key: string): PagerContract;
    setPager(key: string, item: PagerContract): void;
    getColumns(key: string): Columns;
    setColumns(key: string, item: Columns): void;
}

export const useViewStateStore = defineStore('filters', () =>
{
    const key = ref('');
    const filters = ref<Record<string, any>>({});
    const pager = ref<Record<string, any>>({});
    const columns = ref<Record<string, any>>({});

    function getFilters(key: string): FilterState
    {
        return filters.value[key] || {
            activeTemplate: {
                name: '',
                id: 0
            },
            visibleFilters: false
        };
    }

    function setFilters(key: string, item: FilterState): void
    {
        filters.value[key] = item;
    }

    function getPager(key: string): PagerContract
    {
        return pager.value[key];
    }

    function setPager(key: string, item: PagerContract): void
    {
        pager.value[key] = item;
    }

    function getColumns(key: string): Columns
    {
        return columns.value[key] || {
            visible: {},
            positions: {}
        };
    }

    function setColumns(key: string, item: Columns): void
    {
        columns.value[key] = item;
    }

    return {key, filters, pager, columns, getFilters, setFilters, getPager, setPager, getColumns, setColumns};
},
{
    persist: true,
});
