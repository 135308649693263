<template>
    <div class="d-flex align-items-center mt-2">
        <div class="me-2">{{ $t("[[[Aktualny styl numeracji:]]]") }}</div>
        <draggable
            class="syntax-options"
            :list="modelValue"
            :item-key="(element) => element.symbol"
            :component-data="{tag: 'div', wrap: true, clone: true}"
            @start="drag=true" @end="drag=false"
            ghost-class="ghost"
            group="syntax-options"
        >
            <template #item="{element}">
                <div class="syntax-element">
                    {{ element. displayName }}
                </div>
            </template>
        </draggable>
    </div>
    <div class="d-flex align-items-center mt-2">
        <div class="me-2">{{ $t("[[[Dostępne opcje:]]]") }}</div>
        <draggable
            class="syntax-options"
            :list="options"
            :item-key="(element) => element.symbol"
            :component-data="{tag: 'div'}"
            @start="drag=true"
            @end="drag=false"
            :group="{name:'syntax-options', pull:'clone', put: false}"
            ghost-class="ghost"
        >
            <template #item="{element}">
                <div class="syntax-element">
                    {{ element. displayName }}
                </div>
            </template>
        </draggable>
    </div>
    <div class="d-flex align-items-center mt-2">
        <div class="me-2">{{ $t("[[[Kosz:]]]") }}</div>
        <draggable
            class="syntax-options"
            :list="trash"
            :item-key="(element) => element.symbol"
            :component-data="{tag: 'div'}"
            @start="drag=true"
            @end="drag=false"
            group="syntax-options"
            ghost-class="ghost"
        >
            <template #item="{element}">
                <div class="syntax-element">
                    {{ element. displayName }}
                </div>
            </template>
        </draggable>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import draggable from 'vuedraggable';

export interface BaseSyntax {
    type: number
    symbol: string
    displayName: string
    currentValue: string
}

@Options({
    name: 'document-numeration',
    components: {
        draggable
    }
})
export default class DocumentNumeration extends Vue
{
    @Prop({ default: null }) public modelValue: any;
    @Prop() public options: BaseSyntax[];

    public model: any[] = null;
    public trash: any[] = null;
    private drag: boolean = false;

}
</script>

<style lang="scss" scoped>
.syntax-options{
    display: flex;
    flex-direction: row;
    gap: 12px;
    min-height: 40px;
}

.syntax-element {
    padding:10px;
    position: relative;
    box-shadow: 0 3px 7px rgba($color: #000000, $alpha: 0.1);
    border: 1px solid rgba(147, 147, 147, 0.7);
    border-radius: 5px;
    cursor: move;

    .handle {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-left: 10px;
    }

    &.disabled {
        background-color: #e1e6ef;
        border-color: #9faecb;
    }
}
</style>
