<template>
    <template v-if="configuration.withRedirect">
        <router-link :to="{name: configuration.route, params: params}">{{ item.result[configuration.field]?.name }}</router-link>
    </template>
    <span v-else>{{ item.result[configuration.field]?.name }}</span>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { BaseFormatterMixin } from '@/components/dynamicgird/mixins/BaseFormatterMixin';

@Options({})
export default class DeliveryMethodFormatter extends mixins(BaseFormatterMixin)
{

}
</script>
