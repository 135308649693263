import { Options, Vue } from 'vue-class-component';
import { Option } from '@/helpers/Interfaces';
import LocalizationZonesService, {ZoneAutocompleteModel} from '@/modules/wms/configuration/localizationZones/services/LocalizationZonesService';

@Options({
    name: 'ZonesAutocompleteMixin'
})
export class ZonesAutocompleteMixin extends Vue
{
    protected async getZonesList(search: string, limit: number, withoutUsersAndDefaultZones: boolean = true):Promise<any[]>
    {
        const data = await LocalizationZonesService.getAutocompleteList(search, limit, withoutUsersAndDefaultZones);

        return data.map((item: ZoneAutocompleteModel) =>
        {
            return {
                value: item.publicId,
                text: item.name
            };
        });
    }

    protected async fetchZone(id: string): Promise<Option>
    {
        if (id)
        {
            const response = await LocalizationZonesService.fetchAutocompleteModel(id);

            if (response)
            {
                return {
                    value: response.publicId,
                    text: response.name
                };
            }
        }
    }
}
