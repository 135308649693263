/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'reports',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'create',
                name: 'report-create-custom',
                meta: { auth: true },
                component: () => import('@/modules/report/custom/views/CreateReport.vue')
            },
        ]
    }
];
