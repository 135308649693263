<template>
    <div v-if="props.gtinValidationEnabled && props.gtinValidationResult">
        <div v-if="!props.productExist && (props.gtinValidationResult.notValidLength || props.gtinValidationResult.withLetterOrSpecialCharacter)">
            <span class="text-danger mt-2" v-if="props.gtinValidationResult.notValidLength">{{ $t(`[[[Nieprawidłowa długość kodu dla GTIN]]]`) }}</span>
            <span class="text-danger mt-2" v-if="props.gtinValidationResult.withLetterOrSpecialCharacter">{{ $t(`[[[Kod GTIN musi zawierać tylko cyfry]]]`) }}</span>
        </div>
        <div class="text-danger mt-2" v-if="props.gtinValidationResult.checkDigitIsDifferent">
            <span>{{ $t(`[[[Niewłaściwa cyfra kontrolna! Cyfra kontrolna dla wybranego produktu to: %0|||${props.gtinValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
        <div v-else-if="!props.gtinValidationResult.anyError" class="text-danger mt-2">
            <span>{{ $t(`[[[Cyfra kontrolna: %0|||${props.gtinValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ValidateCodeResult } from '@/modules/wms/common/services/Gs1Service';

interface Props {
    gtinValidationEnabled: boolean;
    gtinValidationResult: ValidateCodeResult;
    productExist: boolean;
}

const props = withDefaults(defineProps<Props>(), {});


</script>
