import { computed, ref } from 'vue';
import { defineStore } from "pinia";
import { LicenceModel } from "@/modules/wms/configuration/settings/services/WmsSettingsService";

export enum AppAccessEnum {
    mes = 'mes',
    wms = 'wms',
    default = 'default',
}

export interface LicencesStoreModel {
    licences: LicenceModel
    appAccess: AppAccessEnum
    appDocumentTitle: string
}

export const useLicencesStore = defineStore('licences', () =>
{
    const licences = ref<LicenceModel>(null);
    const appAccess = computed(() =>
    {
        if (!licences.value)
            return AppAccessEnum.default;

        const applicationAccess = licences.value.applicationAccess;

        if (!applicationAccess)
            return AppAccessEnum.default;

        const wmsAccess = applicationAccess.find((x: any) => x.id == 'wms')?.value ?? false;
        const mesAccess = applicationAccess.find((x: any) => x.id == 'mes')?.value ?? false;

        if (mesAccess && !wmsAccess)
            return AppAccessEnum.mes;
        else if (wmsAccess && !mesAccess)
            return AppAccessEnum.wms;

        return AppAccessEnum.default;
    });
    const appDocumentTitle = computed(() =>
    {
        switch (appAccess.value)
        {
            case AppAccessEnum.mes:
                return 'Asiston MES';
            case AppAccessEnum.wms:
                return 'Asiston WMS';
            default:
                return 'Asiston';
        }
    });

    return { appAccess, licences, appDocumentTitle };
});
