<script lang="ts" setup>
import { DynamicGridColumn } from '../../services/DynamicGridService';
import { Resource } from '@/helpers/Interfaces';

interface Props
{
    configuration: DynamicGridColumn
    item: Resource<any>
}

defineOptions({
    name: 'progress-bar-formatter',
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});

</script>

<template>
    <div class="progress-bar" :class="{'mobile': mobile}" v-if="item.result[configuration.field] != null">
        <div class="back">{{ props.item.result[configuration.field] }}%</div>
        <div class="front" :style="{clipPath: `inset(0 0 0 ${props.item.result[configuration.field]}%)`}">{{ props.item.result[configuration.field] }}%</div>
    </div>
</template>

<style lang="scss" scoped>
.progress-bar {
    &.mobile {
        width: 100%;
    }
}
</style>
