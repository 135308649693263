export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-07-05T09:40:34.431Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-07-05T09:40:35.398Z",
    "Last-Translator": "",
    "Language-Team": "",
    "Language": "en-US",
    "Messages": {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        " - rozkładanie": " - unfolding",
        " - zbieranie": " - collecting",
        " do {0}": " to {0}",
        " Konfiguracja widoków ": " Configuration of views ",
        " lokalizacji: ": " location: ",
        " strefy: ": " zones :",
        " Wybierz rolę uprawnień ": " Select a privilege role ",
        "-": "-",
        "...": "...",
        "' + file.filename + '": "' + file.filename + '",
        "#": "#",
        "%0 z %1": "%0 z %1",
        "%0 znaków": "%0 characters",
        "<Brak nazwy>": "<Unnamed>",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "${copied ? 'Copied' : 'Copy'}",
        "${el.text}": "${el.text}",
        "${getCurrentScope().label}": "${getCurrentScope().label}",
        "${isActive(item) ? 'Odznacz' : 'Zaznacz'}": "${isActive(item) ? 'Deselect' : 'Select'}",
        "${item.entityName}": "${item.entityName}",
        "${item.text}": "${item.text}",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "${loading ? 'Loading elements ...' : 'No elements found.'}",
        "${props.inputPlaceholder}": "${props.inputPlaceholder}",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "${tokenVisibility ? 'Hide' : 'Show'} token",
        "1 godzina": "1 hour",
        "1:1": "1:1",
        "10 minut": "10 minutes",
        "12 godzin": "12 hours",
        "15 minut": "15 minutes",
        "2 dni": "2 days",
        "2 miesiące": "2 months",
        "2 tygodnie": "2 weeks",
        "24 godziny": "24 hours",
        "3 dni": "3 days",
        "3 godziny": "3 hours",
        "3 miesiące": "3 months",
        "30 minut": "30 minutes",
        "4 dni": "4 days",
        "4:7": "4:7",
        "5 dni": "5 days",
        "5 minut": "5 minutes",
        "6 dni": "6 days",
        "6 godzin": "6 hours",
        "7:4": "7:4",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form.",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "To test the correctness of the keys, first save the form.",
        "Adaptive sampling": "Adaptive sampling",
        "Administracja": "Administration",
        "Administracja - Kraje": "Administration - Countries",
        "Administracja - Miasta": "Administration - Cities",
        "Administracja - Role": "Administration - Roles",
        "Administracja - Stanowiska pracy": "Administration - Positions",
        "Administracja - Użytkownicy": "Administration - Users",
        "Administracja - Województwa": "Administration - Voivodships",
        "Administracja - Zespoły": "Administration - Teams",
        "Administratorzy": "Administrators",
        "Adres": "Address",
        "Adres do serwisu OneSignal.": "OneSignal website address.",
        "Adres domenowy": "Domain address",
        "Adres dostawy": "Delivery address",
        "Adres e-mail": "E-mail address",
        "Adres e-mail potwierdzony": "The email address has been confirmed",
        "Adres email": "Email address",
        "Adres email nadawcy": "Sender's email address",
        "Adres IP": "IP address",
        "Adres IP: %0": "IP address: %0",
        "Adres serwera": "Server address",
        "Adres URL": "URL",
        "Adv": "Adv",
        "Agreguj": "Aggregate",
        "AI": "AI",
        "Akcent": "Accent",
        "Akceptacja": "Acceptance",
        "Akceptacja dokumentów": "Acceptance of documents",
        "Akceptacja dokumentu": "Acceptance of the document",
        "Akceptacja własnych dokumentów": "Acceptance of own documents",
        "Akceptacja wszystkich dokumentów": "Acceptance of all documents",
        "Akceptacje": "Acceptances",
        "Akceptowany format danych": "Accepted data format",
        "Akceptuj": "Accept",
        "Akcja": "Action",
        "Akcja błąd": "Action error",
        "Akcja sukces": "The action was a success",
        "Akcje": "Actions",
        "Aktualizacja: {0}": "Update: {0}",
        "Aktualizuj": "Update",
        "Aktualna sesja": "Current session",
        "Aktualne hasło": "Current password",
        "Aktualne urządzenie": "Current device",
        "Aktualne zapełnienie": "Current filling",
        "Aktualny styl numeracji:": "Current numbering style:",
        "Aktywna": "Active",
        "Aktywne": "Active",
        "Aktywne kanały komunikacji": "Active communication channels",
        "Aktywne sesje": "Active sessions",
        "Aktywni": "Active",
        "Aktywność": "Activity",
        "Aktywny": "Active",
        "Aktywuj powiadomienia": "Activate notifications",
        "Aktywuj tłumaczenia": "Activate translations",
        "Aktywuj typ": "Activate type",
        "Aktywuj zestaw inteligentnych narzędzi": "Activate the smart toolkit",
        "Alias": "Alias",
        "Alias został przepięty.": "Alias has been rewired.",
        "Angielski": "English",
        "Ankieta": "Survey",
        "Anonimowy": "Anonymous",
        "Anulowano dokument.": "The document was canceled.",
        "Anuluj": "Cancel",
        "Aparat": "Camera",
        "Api key": "Api key",
        "Aplikacja": "Application",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "The application will be restarted within a maximum of 60 seconds.",
        "application/json": "application/json",
        "application/x-www-form-urlencoded": "application/x-www-form-urlencoded",
        "Archiwizuj przez maksymalnie (dni)": "Archive for up to (days)",
        "Asystent": "Assistant",
        "Asystent AI": "AI assistant",
        "Asystent został dodany.": "The assistant has been added.",
        "Asystent został zaktualizowany.": "The assistant has been updated.",
        "Asystenta AI": "AI assistant",
        "Autor": "Author",
        "Azure AD": "Azure AD",
        "Azure AI": "Azure AI",
        "Azure Application Insights": "Azure Application Insights",
        "Azure OpenAI": "Azure OpenAI",
        "Baza danych": "Database",
        "Baza danych tego klienta jest nieaktualna.": "This client's database is out of date.",
        "Bazy danych": "Database",
        "Bezpieczeństwo": "Safety",
        "Bezpieczne połączenie SSL": "Secure SSL connection",
        "Bieżący dokument": "Current document",
        "Blokada konta": "Account blocked",
        "Blokowanie dokumentów": "Blocking documents",
        "Blokowanie kont": "Blocking accounts",
        "Blokuj lokalizacje podczas inwentaryzacji": "Lock locations during inventory",
        "Blokuj magazyn podczas inwentaryzacji": "Lock the warehouse during inventory",
        "Blokuj ruch produktów podczas inwentaryzacji": "Block product movement during inventory",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
        "Błąd instalacji rozszerzenia": "Extension installation error",
        "Błąd odświeżania listy rozszerzeń": "Error refreshing extension list",
        "Błąd przeinstalowywania rozszerzenia": "Error reinstalling extension",
        "Błąd skanowania, spróbuj ponownie.": "Scanning error, try again.",
        "Błąd, proszę spróbować jeszcze raz.": "Error, please try again.",
        "Błędne": "Incorrect",
        "Błędny": "Wrong",
        "Błędy": "Errors",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "Validation errors should be assigned to \"entry.errors.value\" as a message array.",
        "Brak": "Lack",
        "Brak aktywnych skrótów": "No active shortcuts",
        "Brak cennika dla wybranego dostawcy.": "There is no price list for the selected supplier.",
        "Brak danych": "No data",
        "Brak danych.": "No data.",
        "Brak dostępnych skrótów": "No shortcuts available",
        "Brak działu nadrzędnego": "No parent section",
        "Brak elementów": "No items",
        "Brak elementów do wygenerowania": "No items to generate",
        "Brak kategorii": "No categories",
        "Brak kluczy": "No keys",
        "Brak kolekcji": "No collection",
        "Brak nowych powiadomień.": "No new notifications.",
        "Brak odpowiedzi": "No response",
        "Brak uprawnienia z licencji.": "No license permission.",
        "Brak uprawnień": "No permissions",
        "Brak wersji": "No version",
        "Brak wyboru": "No choice",
        "Brak wygenerowanych elementów": "No generated items",
        "Brak wyników": "No results",
        "Brak wystarczającej ilości informacji": "Not enough information",
        "Brak zapisanych filtrów.": "No saved filters.",
        "Brak zapytania": "No inquiry",
        "Brak zdefiniowanych ról.": "No defined roles.",
        "Brak zdefiniowanych zespołów.": "No defined teams.",
        "Budynek": "Building",
        "Budynek został dodany.": "The building has been added.",
        "Budynek został usunięty.": "The building has been removed.",
        "Budynek został zaktualizowany.": "The building has been updated.",
        "Budynki": "Buildings",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "The cache will be stored using a Redis server.",
        "Cache po stronie serwera": "Server-side caching",
        "Cache-Control": "Cache-Control",
        "Cel aktywny": "Active target",
        "Cel został dodany.": "The goal has been added.",
        "cel został usunięty.": "cel został usunięty.",
        "Cel został zaktualizowany.": "The goal has been updated.",
        "Cele": "Goals",
        "Cena": "Price",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "Price in dollars of the sum of input and output tokens.",
        "Cennik Azure OpenAI": "Azure OpenAI pricing",
        "Cennik AzureAI": "AzureAI pricing",
        "Cennik OpenAI": "OpenAI price list",
        "Centrum powiadomień": "Notification center",
        "Chat AI": "AI chat",
        "Checkbox": "Checkbox",
        "Ciemny": "Dark",
        "Co 10 dni": "Every 10 days",
        "Co 10 minut": "Every 10 minutes",
        "Co 12 godzin": "Every 12 hours",
        "Co 15 minut": "Every 15 minutes",
        "Co 2 godziny": "Every 2 hours",
        "Co 2 minuty": "Every 2 minutes",
        "Co 2 tygodnie": "Every 2 weeks",
        "Co 20 minut": "Every 20 minutes",
        "Co 3 godziny": "Every 3 hours",
        "co 3 miesiące": "every 3 months",
        "Co 3 miesiące": "Every 3 months",
        "co 30 dni": "every 30 days",
        "Co 30 minut": "Every 30 minutes",
        "Co 4 miesiące": "Every 4 months",
        "Co 5 dni": "Every 5 days",
        "Co 5 minut": "Every 5 minutes",
        "Co 6 godzin": "Every 6 hours",
        "co 6 miesięcy": "every 6 months",
        "Co chcesz wygenerować?": "What do you want to generate?",
        "Co drugi dzień": "Every other day",
        "Co drugi miesiąc": "Every other month",
        "Co godzinę": "Every hour",
        "Co miesiąc": "Every month",
        "Co minutę": "Every minute",
        "Co pół roku": "Every six months",
        "co rok": "every year",
        "Co tydzień": "Every week",
        "Codziennie": "Every day",
        "Connection String": "ConnectionString",
        "Coś poszło nie tak": "Something went wrong",
        "Cron": "Cron",
        "Cyfry": "Digits",
        "Cykl sprzedażowy": "Sales cycle",
        "Czas": "Time",
        "Czas pomiędzy": "Time between",
        "Czas realizacji zamówienia": "Order processing time",
        "Czas trwania blokady": "Account blocking time",
        "Czas uruchomienia": "Start-up time",
        "Czas, w jakim zostanie zrealizowane zamówienie (dni)": "Time in which the order will be processed (days)",
        "Czat z asystentem": "Chat with your assistant",
        "Czerwiec": "June",
        "Członkowie zespołu": "Team members",
        "Cztery kolumny": "Four columns",
        "Czy aby na pewno chesz przywrócić ustawienia domyślne?": "Are you sure you want to restore the default settings?",
        "Czy aby na pewno chesz zrestartować aplikacje?": "Are you sure you want to restart the applications?",
        "Czy chcesz kontynuować?": "Do you want to continue?",
        "Czy chcesz wydrukować etykietę nośnika?": "Do you want to print a carrier label?",
        "Dalej": "Next",
        "DALL-E 3": "DALL-E 3",
        "Dane": "Information",
        "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
        "Dane osobowe": "Personal data",
        "Dane podst.": "Basic info",
        "Dane podstawowe": "Basic information",
        "Dane w tabeli": "Data in the table",
        "Dane wejściowe": "Input data",
        "Dane wyjściowe": "Output",
        "Data": "Date",
        "Data do": "Date to",
        "Data dodania": "Adding date",
        "Data dostarczenia": "Delivery date",
        "Data dostawy": "Delivery date",
        "Data generowania": "Generation date",
        "Data i czas": "Date and time",
        "Data i godzina": "Date and hour",
        "Data modyfikacji": "Modification date",
        "Data od": "Date from",
        "Data opuszczenia zespołu": "Date of leaving the team",
        "Data rejestracji": "Registration date",
        "Data rozpoczęcia": "Start date",
        "Data uruchomienia": "Launch date",
        "Data usunięcia": "Deletion date",
        "Data utworzenia": "Creation date",
        "Data utworzenia wersji": "Version creation date",
        "Data utworzenia: ": "Date of creation:",
        "Data użycia": "Use date",
        "Data wygaśnięcia": "Expiration date",
        "Data wygenerowania treści.": "Date the content was generated.",
        "Data wykluczenia do": "Exclusion date until",
        "Data wykluczenia od": "Date of exclusion from",
        "Data wylogowania": "Logout date",
        "Data wywołania": "Date triggered",
        "Data zakonczenia": "End date",
        "Data zakończenia": "End date",
        "Data zakończenia blokady konta": "Account block end date",
        "Data zalogowania": "Login date",
        "Data zapytania": "Date of inquiry",
        "Data zdarzenia": "Event date",
        "Data: %0 | Status: %1": "Date: %0 | Status: %1",
        "Debug": "Debug",
        "Debugger": "Debugger",
        "Dedykowane": "Dedicated",
        "DeepL": "DeepL",
        "Definicja": "Definition of",
        "Definicja (JSON)": "Definition (JSON)",
        "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
        "Delete": "Delete",
        "DELETE: Usunięcie wpisu": "DELETE: Delete an entry",
        "Dezaktywuj typ": "Deactivate type",
        "Długość": "Length",
        "Długość (%0)": "Length (%0)",
        "Długość [%0]": "Length [%0]",
        "Długość czasu przechowywania": "Length of storage time",
        "Długość trwania sesji (min)": "Session duration (min)",
        "Dłużyca": "Log",
        "Dni": "Days",
        "do": "to",
        "Do": "To",
        "Do akceptacji": "To be approved",
        "Do akceptacji/Zakończony": "To be approved/Completed",
        "do czasu ręcznego odblokowania": "until manually unlocked",
        "Do magazynu": "To the warehouse",
        "Do wygenerowania": "To generate",
        "Docs": "Docs",
        "Dodaj": "Add",
        "Dodaj asystenta": "Add an assistant",
        "Dodaj budynek": "Add building",
        "Dodaj cel": "Add a goal",
        "Dodaj dni": "Add days",
        "Dodaj do zespołu": "Add to team",
        "Dodaj dokument": "Add the document",
        "Dodaj drukarkę": "Add a printer",
        "Dodaj dział": "Add section",
        "Dodaj endpoint": "Add endpoint",
        "Dodaj formularz": "Add form",
        "Dodaj grupę": "Add group",
        "Dodaj inwentaryzację częściową": "Add partial inventory",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "Add one or more widgets to gain insight into your team's progress.",
        "Dodaj kategorię": "Add category",
        "Dodaj klienta": "Add client",
        "Dodaj kod": "Add a code",
        "Dodaj komponent": "Add a component",
        "Dodaj list przewozowy": "Add waybill",
        "Dodaj logo": "Add logo",
        "Dodaj lokalizacje": "Add locations",
        "Dodaj moduł": "Add module",
        "Dodaj nośnik": "Add carrier",
        "Dodaj nowy": "Add new",
        "Dodaj nowy folder": "Add a new folder",
        "Dodaj nowy wątek": "Add a new thread",
        "Dodaj plan": "Add a plan",
        "Dodaj pliki": "Add files",
        "Dodaj pliki asystenta": "Add assistant files",
        "Dodaj poziom": "Add level",
        "Dodaj pozycję": "Add item",
        "Dodaj produkt": "Add product",
        "Dodaj przęsło": "Add a span",
        "Dodaj przyjęcie": "Add a receipt",
        "Dodaj raport": "Add report",
        "Dodaj regułe": "Add a rule",
        "Dodaj rodzaj": "Add type",
        "Dodaj rodzaj nośnika": "Add carrier type",
        "Dodaj rolę": "Add a role",
        "Dodaj rząd": "Add row",
        "Dodaj sekcję": "Add a section",
        "Dodaj serwer": "Add server",
        "Dodaj shortcode": "Add shortcode",
        "Dodaj skrót": "Add a shortcut",
        "Dodaj słowo": "Add the word",
        "Dodaj stanowisko": "Dodaj station",
        "Dodaj strefę": "Add zone",
        "Dodaj stronę": "Add page",
        "Dodaj synonimy": "Add synonyms",
        "Dodaj szablon": "Add template",
        "Dodaj token": "Add token",
        "Dodaj towary": "Add goods",
        "Dodaj towary, aby przekazać dokument do relizacji.": "Add items to submit the document for processing.",
        "Dodaj typ": "Add type",
        "Dodaj użytkownika": "Add user",
        "Dodaj warunek": "Add a condition",
        "Dodaj webhooka": "Add a webhook",
        "Dodaj widget": "Add widget",
        "Dodaj wpis": "Add entry",
        "Dodaj wydanie": "Add a issue",
        "Dodaj Wydanie": "Add Issue",
        "Dodaj załadunek": "Add loading",
        "Dodaj zastępstwo": "Add a replacement",
        "Dodaj zdjęcie": "Add photo",
        "Dodaj zespół": "Add team",
        "Dodaj źródło": "Add source",
        "dodał": "added",
        "dodane i edytowane przeze mnie": "added and edited by me",
        "Dodane przeze mnie": "Added by me",
        "Dodanie": "Adding",
        "Dodanie celu": "Adding a goal",
        "Dodanie klienta": "Add client",
        "Dodanie roli": "Add role",
        "Dodanie serwera": "Add server",
        "Dodanie użytkownika": "Add user",
        "Dodanie źródła": "Add data source",
        "dodany": "added",
        "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
        "Dodatkowe informacje": "Additional information",
        "Dodatkowe kody kreskowe": "Additional barcodes",
        "Dodawanie": "Adding",
        "Dodawanie i edycja własnych dokumentów": "Adding and editing your own documents",
        "Dodawanie nośnika": "Adding carrier",
        "Dokładność miejsc po przecinku dla ilości": "Accuracy of decimal places for quantity",
        "Dokładność miejsc po przecinku dla wagi": "Accuracy of decimal places for the weight",
        "Dokładność miejsc po przecinku dla wymiarów": "Accuracy of decimal places for dimensions",
        "Dokończ rozkładanie": "Complete the unfolding",
        "Dokument": "Document",
        "Dokument %0": "Document %0",
        "Dokument należy odłożyć do": "The document should be put aside until",
        "Dokument PDF": "PDF document",
        "Dokument powiązany": "Related document",
        "Dokument powiązany z przyjęcia": "Document related to admission",
        "Dokument przekazano do magazynu.": "The document was transferred to the warehouse.",
        "Dokument realizujący": "Implementing document",
        "Dokument tekstowy": "Text document",
        "Dokument wygenerowany pomyślnie": "Document generated successfully",
        "Dokument wysłano do ERP.": "The document was sent to ERP.",
        "Dokument zablokowany przez": "Document blocked by",
        "Dokument został anulowany.": "The document has been canceled.",
        "Dokument został usunięty.": "The document has been removed.",
        "Dokument został zwrócony do magazynu.": "The document was returned to the warehouse.",
        "Dokument został zwrócony.": "The document was returned.",
        "Dokumenty": "Documents",
        "Dokumenty powiązane": "Related documents",
        "Dołącz stack trace": "Include stack trace",
        "Domena": "Domain",
        "Domena dla powiadomień": "Domain for notifications",
        "Domena do której będą kierowane powiadomienia": "The domain to which notifications will be directed",
        "Domyślna": "Default",
        "Domyślna lokalizacja": "Default location",
        "Domyślna wartość": "Default value",
        "Domyślne": "Default",
        "Domyślne dane lokalizacji": "Default location data",
        "Domyślne filtry": "Default filters",
        "Domyślnie ukryte": "Hidden by default",
        "Domyślnie widoczne": "By default visible",
        "Domyślny": "Default",
        "Dopuść brak odpowiedzi": "Allow lack of response",
        "Dostarczone": "Delivered",
        "Dostawa": "Delivery",
        "Dostawca": "Supplier",
        "Dostęp do modułu": "Access to the module",
        "Dostęp niezdefiniowany": "Undefined access",
        "Dostęp przyznany": "Access granted",
        "Dostęp zabroniony": "Access denied",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. No update may result in errors.",
        "Dostępne": "Available",
        "Dostępne opcje:": "Options available:",
        "Dostępne skróty": "Available shortcuts",
        "Dostępny limit": "Limit available",
        "Dostępny w pakowaniu": "Available in packaging",
        "dowolnej wybranej wersji": "any selected version",
        "Dowolny": "Any",
        "Dozwolone produkty": "Allowed products",
        "Dozwolone rozszerzenia": "Allowed extensions",
        "Dozwolone rozszerzenia: %0.": "Allowed extensions: %0.",
        "Dozwolone są tylko małe i duże litery": "Only lowercase and uppercase letters are allowed",
        "Dozwolone są tylko małe litery i znak -": "Only lowercase letters and the character are allowed -",
        "Dozwolone typy plików: %0.": "Allowed file types: %0.",
        "DPI": "DPI",
        "Drugie imię": "Middle name",
        "Drugorzędny": "Secondary",
        "Drukarka": "Printer",
        "Drukarka została dodana.": "The printer has been added.",
        "Drukarka została usunięta.": "The printer has been removed.",
        "Drukarka została zaktualizowana.": "The printer has been updated.",
        "Drukarki": "Printers",
        "Drukowane etykiety na urządzeniu": "Printed labels on the device",
        "Drukowanie: %0": "Printing: %0",
        "Drukuj": "Print",
        "Drukuj nośnik": "Print carrier",
        "Drukuj testowo": "Test print",
        "Drukuj wszystkie listy przewozowe": "Print all waybills",
        "Dsn": "Dsn",
        "Duży": "Large",
        "Duży układ siatki": "Large grid layout",
        "Dwie kolumny": "Two columns",
        "Dwuetapowe": "Two-stage",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "The max-age=N directive indicates that the response remains fresh until N seconds after the response is generated.",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "The no-store directive indicates that any cache of any kind should not store this response.",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "The PRIVATE directive indicates that the response can only be stored in a private cache (e.g. local browser memory).",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "The PUBLIC directive indicates that the response can be stored in a shared cache (such as a proxy).",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "The s-maxage directive is ignored by private caches and replaces the value specified by the max-age directive for shared caches.",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "The s-maxage directive indicates how long a response remains valid in a shared cache (such as a proxy).",
        "Dyrektywy zakresu": "Scope directives",
        "Dział aktywny": "Active section",
        "Dział nadrzędny": "Parent section",
        "Dział został dodany.": "The section has been added.",
        "Dział został zaktualizowany.": "The section has been updated.",
        "Działania masowe": "Mass activities",
        "Dzielenie": "Division",
        "dzień": "day",
        "Dzień": "Day",
        "E-mail": "E-mail",
        "EAN": "EAN",
        "Edycja celu": "Edit a goal",
        "Edycja dokumentów": "Edit documents",
        "Edycja dokumentu": "Edit document",
        "Edycja klienta": "Edit client",
        "Edycja roli": "Edit role",
        "Edycja serwera": "Edit server",
        "Edycja użytkownika": "Edit user",
        "Edycja w popupie": "Edit in popup",
        "Edycja wszystkich dokumentów": "Edit all documents",
        "Edycja zlecenia przesunięcia": "Edit transfer order",
        "Edycja źródła": "Edit data source",
        "Edytor": "Editor",
        "Edytowana wersja": "Edited version",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
        "Edytuj": "Edit",
        "Edytuj członka grupy": "Edit group member",
        "Edytuj dokument": "Edit the document",
        "Edytuj filtr": "Edit filter",
        "Edytuj kategorię": "Edit category",
        "Edytuj kod": "Edit code",
        "Edytuj opakowanie": "Edit packaging",
        "Edytuj plan": "Edit plan",
        "Edytuj pozycję": "Edit position",
        "Edytuj rodzaj nośnika": "Edit carrier type",
        "Edytuj szablon": "Edit template",
        "Edytuj uprawnienia": "Edit permissions",
        "Edytuj wpis": "Edit post",
        "Eksport": "Exports",
        "Eksport dokumentów": "Exporting documents",
        "Eksport użytkowników": "Exporting users",
        "Eksportuj do Excela": "Export to Excel",
        "Elastic": "Elastic",
        "Element został przywrócony.": "The element has been restored.",
        "Element został usunięty.": "The element has been deleted.",
        "Email": "E-mail",
        "Encja": "Entity",
        "Endpoint": "Endpoint",
        "Endpoint został dodany.": "Endpoint has been added.",
        "Endpoint został zaktualizowany.": "Endpoint has been updated.",
        "ERP": "ERP",
        "Error - błedy wewnętrze SDK": "Error - internal SDK errors",
        "Eskportuj": "Escort",
        "Etykieta": "Label",
        "Etykieta kodu zbiorczego": "Bulk code label",
        "Etykieta listu przewozowego": "Waybill label",
        "Etykieta lokalizacji": "Location label",
        "Etykieta nośnika stałego": "Carrier label",
        "Etykieta opakowania": "Packaging label",
        "Etykieta produktu": "Product label",
        "Etykiety": "Labels",
        "Ewidencja partii w systemie WMS": "Batch records in the WMS system",
        "Exportuj do Excela": "Export to Excel",
        "Fatal - błedy krytyczne": "Fatal - critical errors",
        "Filtr dla synchronizacji działów": "Filter for department synchronization",
        "Filtr dla synchronizacji użytkowników": "Filter for user synchronization",
        "Filtr domyślny": "Default filter",
        "Filtr globalny": "Global filter",
        "Filtr prywatny": "Private filter",
        "Filtr został usunięty": "Filter has been removed",
        "Filtr został zapisany": "Filter has been saved",
        "Filtrowanie": "Filtering",
        "Filtrowanie listy dokumentów": "Filtering the document list",
        "Filtruj": "Filter",
        "Filtry": "Filters",
        "Filtry globalne": "Global filters",
        "Folder plików": "File folder",
        "Foldery": "Folders",
        "Formularz": "Modules / Contact form",
        "Formularz bazuje na nieaktualnej wersji definicji.": "The form is based on an outdated version of the definition.",
        "Formularz zarchiwizowany": "Archived form",
        "Formularz został dodany.": "The form has been added.",
        "Formularz został skopiowany.": "The form has been copied.",
        "Formularz został zaktualizowany.": "The form has been updated.",
        "Funkcjonalności": "Functionalities",
        "Generator obrazów": "Image generator",
        "Generator obrazów AI": "AI image generator",
        "Generowanie dokumentu": "Document generation",
        "Generowanie listu przewozowego": "Generating a waybill",
        "Generowanie LP": "WB generation",
        "Generowanie obrazów": "Image generation",
        "Generowanie obrazów zakończone.": "Image generation complete.",
        "Generuj": "Generate",
        "Generuj dokument": "Generate document",
        "Generuj list przewozowy": "Generate waybill",
        "Generuj metadane (AI)": "Generate metadata (AI)",
        "Generuj obraz": "Generate image",
        "Generuj odbiór osobisty": "Generate individual pick-up",
        "Get": "Get",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "GET: List of entries (sorting and paging)",
        "GET: Pobranie danych": "GET: Downloading data",
        "GET: Pobranie wpisu": "GET: Downloading an entry",
        "GET: Schemat (definicja formularza)": "GET: schema (form definition)",
        "Głębokość": "Depth",
        "Główny": "Main",
        "Godziny": "Hours",
        "Google Translate V2": "Google Translate V2",
        "Gotowe": "Ready",
        "GPT-4 Vision": "GPT-4 Vision",
        "Grafika": "Graphics",
        "Grudzień": "December",
        "Grupa": "Group",
        "Grupy produktowe": "Product groups",
        "Harmonogram zadań": "Task scheduler",
        "Harmonogram został zmieniony": "The schedule has been revised",
        "Hasło": "Password",
        "Hasło (Klucz ukryty)": "Password (Hidden Key)",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
        "Hasło użytkownika serwisowego": "Service user password",
        "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Sign in again",
        "Health": "Health",
        "Hierarchia": "Hierarchy",
        "Historia": "History",
        "Historia logowania": "Login history",
        "Historia ruchów magazynowych": "History of warehouse movements",
        "Historia zadania": "Task history",
        "Horyzontalna": "Horizontal",
        "Host": "Host",
        "Host dodatkowy": "Secondary host",
        "Host główny": "Main host",
        "i": "and",
        "Id": "Id",
        "ID": "ID",
        "ID lub typ zadania": "ID or task type",
        "Id modelu: %0": "Model Id: %0",
        "Id obiektu": "Object ID",
        "Id rozmowy": "Call id",
        "Id sesji": "Session Id",
        "Id wpisu": "Entry id",
        "ID zadania": "Task ID",
        "Id zapytania": "Query ID",
        "Id zdarzenia": "Event Id",
        "Identyfikator": "ID",
        "Identyfikator przestrzeni (WorkspaceId)": "Space ID (WorkspaceId)",
        "Identyfikator wdrożenia": "Deployment ID",
        "Identyfikator zadania": "Task ID",
        "Identyfikator zapytania.": "The query ID.",
        "Ikona": "Icon",
        "Ikona chatu AI": "AI chat icon",
        "Ilości dni, na które mamy zamiar zamówić": "The number of days for which we intend to order",
        "Ilość": "Quantity",
        "Ilość dostępna": "Quantity available",
        "Ilość lokalizacji": "Number of locations",
        "Ilość na ZD": "Quantity per ZD",
        "Ilość nie może być mniejsza lub równa 0, w celu usunięcia pozycji należy użyć opcji usuwania": "The quantity cannot be less than or equal to 0, to delete the item use the delete option",
        "Ilość paczek/palet": "Number of packages/pallets",
        "Ilość palet na lokalizacji": "Number of pallets per location",
        "Ilość palet:": "Number of pallets:",
        "Ilość powtórzeń": "Number of repetitions",
        "Ilość poziomów": "Number of levels",
        "Ilość przęseł": "Number of spans",
        "Ilość przyjęta": "Quantity received",
        "Ilość towaru z obliczeń": "Quantity of goods from calculations",
        "Ilość wierszy": "Number of rows",
        "Ilość wyników do odrzucenia": "Number of results to be rejected",
        "Ilość wyników do pobrania": "Number of results to download",
        "Ilość zamawiana PJM": "PJM order quantity",
        "Ilość znaków": "Number of characters",
        "Ilość znaków odpowiedzi": "Number of response characters",
        "Ilość została zaktualizowana.": "The quantity has been updated.",
        "Imię": "First name",
        "Imię i Nazwisko": "First name and last name",
        "Import": "Imports",
        "Import modułu": "Module import",
        "Import użytkowników": "Importing users",
        "Importuj definicję formularza": "Import form definition",
        "Indeks": "Index",
        "Indeks został usunięty.": "The index has been removed.",
        "Indywidualny kod": "Individual code",
        "Info": "Info",
        "Info - informacje": "Info - informacje",
        "Informacja": "Information",
        "Informacje": "Information",
        "Informator": "Guide",
        "Inspekcja": "Inspection",
        "Instalacja": "Installation",
        "Instaluj": "Install",
        "Instrukcja": "Instruction",
        "Instrukcja modelu (prompt).": "Model statement (prompt).",
        "Interpreter kodu": "Code interpreter",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "The code interpreter allows the assistant to write and run code. This tool can process files with various data and formatting and generate files.",
        "Inwentaryzacja": "Inventory",
        "Inwentaryzacja częściowa została dodana.": "The partial inventory has been added.",
        "Inwentaryzacja częściowa została usunięta.": "The partial inventory has been removed.",
        "Inwentaryzacja zbiorcza została dodana.": "The summary inventory has been added.",
        "Inwentaryzacja zbiorcza została usunięta.": "The summary inventory has been removed.",
        "Inwentaryzacje częściowe": "Partial inventories",
        "Jakość": "Quality",
        "Jakość HD 1024x1024": "HD quality 1024x1024",
        "Jakość HD 1024x1792 i 1792x1024": "HD quality 1024x1792 and 1792x1024",
        "Jakość standardowa 1024x1024": "Standard quality 1024x1024",
        "Jakość standardowa 1024x1792 i 1792x1024": "Standard quality 1024x1792 and 1792x1024",
        "Jakość wygnerowanego obrazu.": "Quality of the generated image.",
        "Jasny": "Bright",
        "Jedna kolumna": "One column",
        "Jednoetapowe": "One-step",
        "Jednoetapowo": "One-step",
        "Jednostka miary": "Unit",
        "Jednostka miary dla wagi": "Unit of measure for weight",
        "Jednostka miary dla wymiarów": "Unit of measurement for dimensions",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "If the field is empty, then the default value is used. Otherwise, the default value is overwritten.",
        "Jm": "Iu",
        "JM": "IU",
        "Kalendarz": "Calendar",
        "Kalendarz - Kategorie zasobów": "Calendar - Resource Categories",
        "Kalendarz dostaw": "Delivery calendar",
        "Kalkulator pakowania": "Packing calculator",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
        "kanał wymagany": "required channel",
        "Kanały": "Channels",
        "Kanały komunikacji": "Communication channels",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
        "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
        "Katalog": "Directory",
        "Katalog główny": "Main directory",
        "Katalog został utworzony": "The directory has been created",
        "Katalog został utworzony.": "The directory has been created.",
        "Kategoria": "Category",
        "Kategoria jest wymagana": "Category is required",
        "Kategoria została dodana.": "The category has been added.",
        "Kategoria została usunięta.": "The category has been removed.",
        "Kategoria została zaktualizowana.": "The category has been updated.",
        "Kategorie": "Categories",
        "Klasa rotacyjności": "Rotation class",
        "Klawiatura numeryczna": "Numeric keypad",
        "Klienci": "Clients",
        "Klient aktywny": "Active client",
        "Klient został dodany.": "The client has been added.",
        "Klient został usunięty.": "The client has been deleted.",
        "Klient został zaktualizowany.": "The client has been updated.",
        "Klucz": "Key",
        "Klucz API": "API key",
        "Klucz aplikacji. Znajduje się w `Platformach`": "Application key. It is located in `Platforms`",
        "Klucz główny (PrimaryKey)": "Primary Key (PrimaryKey)",
        "Klucz prywatny": "Private key",
        "Klucz publiczny": "Public key",
        "Klucze": "Keys",
        "Klucze są nie prawidłowe.": "The keys are incorrect.",
        "Klucze są prawidłowe.": "The keys are correct.",
        "Kod": "Code",
        "Kod dostępu": "Kod dostępu",
        "Kod dostępu stanowiska": "Station access code",
        "Kod kreskowy": "Barcode",
        "Kod lokalizacji w strefie": "Zone location code",
        "Kod pin": "Pin",
        "Kod PIN": "PIN code",
        "Kod pocztowy": "Postal code",
        "Kod regału": "Rack code",
        "Kod towaru": "Commodity code",
        "Kod walidacji": "Validation code",
        "Kod wertfikacyjny": "Verification code",
        "Kod weryfikacyjny jest nieprawidłowy.": "The verification code is invalid.",
        "Kod wpisany poprawnie": "Code entered correctly",
        "Kod zbiorczy został dodany.": "The cumulative code has been added.",
        "Kod zbiorczy został zaktualizowany.": "The cumulative code has been updated.",
        "Kod został usunięty.": "The code has been removed.",
        "Kod źródłowy": "Source code",
        "Kody zbiorcze": "Cumulative codes",
        "Kolejka": "Queue",
        "Kolejka wydania": "Issue queue",
        "Kolejki wydań": "Issue queues",
        "Kolor drugorzędny: %0": "Secondary color: %0",
        "Kolor icon na panelu z aplikacjami: %0": "The color of the icon on the app panel: %0",
        "Kolor podstawowy: %0": "Basic color: %0",
        "Kolor przewodni obrazu": "Leading image color",
        "Kolor tekstu aktywnej pozycji menu: %0": "The text color of the active menu item: %0",
        "Kolor tła panelu z aplikacjami: %0": "Background color of the application panel: %0",
        "Kolorowana konsola": "Colored console",
        "Kolory zostały przywrócone.": "The colors have been restored.",
        "Kolory zostały zapisane.": "The colors have been recorded.",
        "Kolumny": "Description column name",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "A component whose value will be used as the name of the parent element. Only ``Text`` components available.",
        "Komponent został dodany.": "The component has been added.",
        "Komponent został usunięty.": "The component has been removed.",
        "Komponent został zaktualizowany.": "The component has been updated.",
        "Komponent źródłowy": "Source component",
        "Komunikat": "Message",
        "Komunikat błędu": "Error message",
        "Komunikat walidacji": "Validation message",
        "Konfiguracja": "Settings",
        "Konfiguracja asystenta ai": "AI assistant setup",
        "Konfiguracja domyślna": "Default configuration",
        "Konfiguracja harmonogramu": "Schedule configuration",
        "Konfiguracja kolumn tabeli": "Table column configuration",
        "Konfiguracja logów": "Log configuration",
        "Konfiguracja lokalizacji": "Location configuration",
        "Konfiguracja rzędów": "Row configuration",
        "Konfiguracja ścieżki": "Track configuration",
        "Konfiguracja zaawansowana": "Advanced configuration",
        "Konfiguracja zapytania": "Query configuration",
        "Konfiguracja została przywrócona dla reguł i celów": "The configuration has been restored for rules and targets",
        "Konfigurator lokalizacji": "Location configurator",
        "Konta użytkowników": "User accounts",
        "Konta użytkowników zostały odblokowane": "User accounts have been unlocked",
        "Konta użytkowników zostały przypisane do zespołu": "User accounts have been assigned to the team",
        "Konta użytkowników zostały usunięte z systemu": "User accounts have been removed from the system",
        "Konta użytkowników zostały zablokowane": "User accounts have been blocked",
        "Kontakt": "Contact",
        "Kontakty - Typ działania": "Contacts - Activity Type",
        "Kontener plików": "File container",
        "Konto aktywne": "Active account",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "Account will remain inactive until email address is confirmed",
        "Kontrahent": "Contractor",
        "Kontrola jakości": "Quality control",
        "Kontrolka wyboru (pojedynczy wybór)": "Choice control (single choice)",
        "Kontrolka wyboru (wybór wielokrotny)": "Choice control (multiple choice)",
        "Konwertuj obrazy do formatu WebP": "Konwertuj obrazy do formatu WebP",
        "Końcowa": "Final",
        "Kopiuj": "Copy",
        "Kopiuj adres": "Copy address",
        "Kopiuj definicję": "Copy definition",
        "Kopiuj pole": "Copy field",
        "Kosz": "Bin",
        "Kosz:": "Recycle bin:",
        "Kraj": "Country",
        "Krok minut": "Minute step",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "The minute step must be a number between 1 and 60.",
        "Kroki": "Steps",
        "Krótki opis": "Short description",
        "Kurier": "Courier",
        "Kurierzy": "Couriers",
        "Kwiecień": "April",
        "LDAPS (LDAP over SSL)": "LDAPS (LDAP over SSL)",
        "Lewy panel": "Left panel",
        "Licencja": "License",
        "Liczba": "Number",
        "Liczba całkowita": "Integer",
        "Liczba danych wejściowych podana w tokenach.": "The number of inputs given in tokens.",
        "Liczba danych wyjściowych podana w tokenach.": "The number of output given in tokens.",
        "Liczba dni na ile chcesz zamówić": "Number of days for how long you want to order",
        "Liczba dni na ile wystarczy towaru": "Number of days for how long the goods will last",
        "Liczba dziesiętna": "Decimal",
        "Liczba miesięcy": "Number of months",
        "Liczba pozycji dokumentu": "Number of document items",
        "Liczba punktów sprzedaży": "Number of sales points",
        "Liczba wariantów": "Number of variants",
        "Liczba wyników na stronie": "Number of results per page",
        "Liczniki dokumentów": "Document counters",
        "Lider": "Leader",
        "Limit": "Limit",
        "Limit API": "API limit",
        "Linia": "Line",
        "linie": "lines",
        "Link": "Link",
        "Lipiec": "July",
        "List przewozowy": "Waybill",
        "List przewozowy został dodany.": "The waybill has been added.",
        "List przewozowy został wygenerowany.": "The waybill has been generated.",
        "Lista dokumentów": "List of documents",
        "Lista dozwolonych / wykluczonych żądań HTTP": "List of allowed/excluded HTTP requests",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "The list of elements will be narrowed down to only those that are related to the selected values ​​from the selected components.",
        "Lista jest pusta": "The list is empty",
        "Lista planów": "Plan list",
        "Lista plików": "File list",
        "Lista powiadomień": "Notification list",
        "Lista rozwijana": "Drop-down list",
        "Listopad": "November",
        "Listy przewozowe": "Waybills",
        "Litera": "Letter",
        "Litery": "Letters",
        "Litery i cyfry": "Letters and numbers",
        "Log Analytics": "Log Analytics",
        "Logi": "Logs",
        "Logi wydajnościowe": "Performance logs",
        "Login": "Login",
        "Logo": "Logo",
        "Logowanie": "Log in",
        "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
        "Logowanie zależności / żądań typu HTTP": "Logowanie zależności / żądań typu HTTP",
        "Loguj wybrane": "Log selected",
        "Lok. końc.": "Loc. end",
        "Lok. pocz.": "Loc. start",
        "Lokalizacja": "Location",
        "Lokalizacja końcowa": "End location",
        "Lokalizacja początkowa": "Initial location",
        "Lokalizacja początkowa i końcowa nie może być taka sama.": "The initial and ending locations cannot be the same.",
        "Lokalizacja została dodana.": "The location has been added.",
        "Lokalizacja została usunięta.": "The location has been removed.",
        "Lokalizacja została zaktualizowana.": "The location has been updated.",
        "Lokalizacja:": "Location:",
        "Lokalizacje": "Locations",
        "Luty": "February",
        "Ładowanie...": "Landing...",
        "Łączenie treści": "Combining content",
        "Łącznie na magazynie": "Total in stock",
        "Magazyn": "Warehouse",
        "Magazyn ERP": "ERP warehouse",
        "Magazyny": "Warehouses",
        "Maj": "May",
        "Maksimum": "Maximum",
        "Maksymalna ilość do zeskanowania (0 - bez limitu)": "Maximum amount to scan (0 - no limit)",
        "Maksymalna ilość plików": "Maximum number of files",
        "Maksymalna rozdzielość %0x%1px": "Maximum resolution %0x%1px",
        "Maksymalna waga jednego towaru": "Maximum weight of one commodity",
        "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
        "Maksymalny rozmiar pliku: %0MB.": "Maximum file size: %0MB.",
        "Małe znaki": "Small characters",
        "Mały": "Small",
        "Mały układ siatki": "Small grid layout",
        "Mapowanie": "Mapping",
        "Mappingi": "Mappings",
        "Marketplace": "Marketplace",
        "Marzec": "March",
        "Masa": "Weight",
        "Masa [%0]": "Weight [%0]",
        "Masz niezapisane zmiany. Czy na pewno chcesz wyjść?": "You have unsaved changes. Are you sure you want to leave?",
        "Masz niezapisane zmiany. Czy na pewno chcesz wykonać tą czynność?": "You have unsaved changes. Are you sure you want to perform this action?",
        "Metadane": "Metadata",
        "Metoda": "Method",
        "Metoda musi zwracać obiekt \"entry.errors\".": "The method must return an \"entry.errors\" object.",
        "Metody rozchodu zapasu": "Inventory expenditure methods",
        "Miasto": "City",
        "Miejsca po przecinku": "Decimal points",
        "Miejscowość": "Town",
        "miesiąc": "month",
        "Miesiąc": "Month",
        "Miesiące": "Months",
        "Miesięczny": "Monthly",
        "Międzymagazynowe": "Inter-warehouse",
        "Migracje": "Migrations",
        "Miniaturki": "Thumbnails",
        "Minimalna długość hasła": "Minimum password length",
        "Minimalna średnia arytmetyczna": "Minimum arithmetic mean",
        "Minimum": "Minimum",
        "Minimum jedna kolumna musi pozostać widoczna": "A minimum of one column must remain visible",
        "Minuty": "Minutes",
        "Mnożenie": "Multiplication",
        "Model": "Model",
        "Model AI użyty do wygenerowanej treści.": "AI model used for the generated content.",
        "Model danych": "Data model",
        "Moderacja danych wejściowych": "Moderation of input data",
        "Moduł": "Module",
        "Moduł został dodany.": "The module has been added.",
        "Moduł został usunięty.": "The module has been removed.",
        "Moduł został wyeksportowany.": "The module has been exported.",
        "Moduł został zaktualizowany.": "The module has been updated.",
        "Modyfikacja": "Modification",
        "Modyfikacja dokumentów": "Document modification",
        "Modyfikuj istniejący wątek": "Modify an existing thread",
        "Moje dokumenty": "My Documents",
        "Moje filtry": "My filters",
        "Moje formularze": "My forms",
        "Moje konto": "My account",
        "Moje pliki": "My files",
        "Monitoring": "Monitoring",
        "Monitoruj statystyki .NET": "Monitor .NET statistics",
        "Monitoruj żądania HTTP": "Monitor HTTP requests",
        "Możesz podać nową nazwę pliku.": "You can provide a new file name.",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this section is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "The ability to modify this account is limited. Inactive fields are synchronized with an external database %0.",
        "Możliwość wielokrotnego wyboru": "Multiple choice",
        "Mój tytuł": "My title",
        "N/A": "N/A",
        "Na ile dni chcesz zamówić": "For how many days you want to order",
        "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
        "Na: %0": "To: %0",
        "Nadawanie uprawnień do dokumentów": "Granting rights to documents",
        "Nadawanie uprawnień do własnych dokumentów": "Granting rights to your own documents",
        "Nadawanie uprawnień do wszystkich dokumentów": "Granting rights to all documents",
        "Nadawca": "Sender",
        "Nagłówek": "Header",
        "Nagłówek dokumentu": "Document header",
        "Najstarsza wiadomość": "Oldest message",
        "Należy sprawdzić towary na podanej lokalizacji oraz na innych, na których dane towary się znajdują": "Check the goods on the specified location and on others where the goods are located",
        "Należy sprawdzić towary tylko na podanej lokalizacji. Jeśli towary znajdują się na innych lokalizacjach, wówczas ich stan zostanie automatycznie dodany": "You should check the goods only on the specified location. If the goods are on other locations, then their status will be automatically added",
        "Należy wybrać encje": "Entities must be selected",
        "Należy zaznaczyć conajmniej 1 dokument": "Należy zaznaczyć conajmniej 1 dokument",
        "Napisz mi polecenie :-)": "Write me a recommendation :-)",
        "Narzędzia": "Tools",
        "Narzędzie integruje się z platformą SMSAPI.pl": "The tool integrates with the SMSAPI.pl platform",
        "Następna strona": "Next page",
        "Nazwa": "Name",
        "Nazwa aliasu": "Alias name",
        "Nazwa asystenta": "Assistant name",
        "Nazwa bazy danych": "Database name",
        "Nazwa budynku": "Name of the building",
        "Nazwa działu": "Department name",
        "Nazwa filtra": "Filter name",
        "Nazwa formularza": "Form name",
        "Nazwa indeksu": "Index name",
        "Nazwa jest wymagana": "The name is required",
        "Nazwa katalogu": "Directory name",
        "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
        "Nazwa klienta": "Client name",
        "Nazwa kolumny": "Column name",
        "Nazwa kolumny z opisem": "Description column name",
        "Nazwa kolumny z wartością": "Column name with value",
        "Nazwa komponentu": "Component name",
        "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
        "Nazwa modelu": "Model name",
        "Nazwa modułu": "Module name",
        "Nazwa musi mieć co najmniej 3 znaki": "The name must have at least 3 characters",
        "Nazwa musi mieć co najmniej 5 znaków": "The name must be at least 5 characters long",
        "Nazwa nadawcy": "Sender name",
        "Nazwa narzędzia": "Tool name",
        "Nazwa nie może być dłuższa niż 30 znaków": "The name must not be longer than 30 characters",
        "Nazwa nie może być dłuższy niż 50 znaków": "The name must not be longer than 50 characters",
        "Nazwa nośnika": "Carrier name",
        "Nazwa obiektu": "Object name",
        "Nazwa pliku": "File name",
        "Nazwa pliku została zmieniona.": "The file has been renamed.",
        "Nazwa pola": "Field name",
        "Nazwa powiadomienia": "Notification name",
        "Nazwa produktu": "Product name",
        "Nazwa przycisku ``Dalej``": "Button name ''Next''",
        "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
        "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
        "Nazwa raportu": "Report name",
        "Nazwa rodzaju nośnika": "The name of the media type",
        "Nazwa roli": "Role name",
        "Nazwa sekcji w menu": "Name of the section in the menu",
        "Nazwa sekcji...": "Section name...",
        "Nazwa serwera": "Server name",
        "Nazwa stanowiska": "Job title",
        "Nazwa strefy": "Zone name",
        "Nazwa szablonu": "Template name",
        "Nazwa towaru": "Product name",
        "Nazwa typu": "Type name",
        "Nazwa urządzenia": "Device name",
        "Nazwa użytkownika": "User name",
        "Nazwa użytkownika serwisowego": "Service user name",
        "Nazwa wątku": "Thread name",
        "Nazwa webhooka": "The name of the webhook",
        "Nazwa zadania": "Task name",
        "Nazwa zasobu": "Name of the resource",
        "Nazwa zespołu": "Team name",
        "Nazwa źródła": "Source name",
        "Nazwa źródła danych": "Data source name",
        "Nazwa:": "Name:",
        "Nazwisko": "Last name",
        "Nazwy wartości": "Names of values",
        "Nicość - nie zapisuj": "Nothingness - don't write down",
        "Nie": "No",
        "NIE": "NO",
        "Nie loguj wybranych": "Do not log selected ones",
        "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
        "Nie masz uprawnień do tego dokumentu": "You don't have the permission to this document",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "You do not have permissions for this view. The state of the view may have changed, refresh and check or try again later.",
        "Nie możesz dodać tego samego widgetu dwa razy": "You can't add the same widget twice",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "You cannot modify the profile of the replaced user.",
        "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
        "Nie można usunąć swojego konta.": "You can't delete your account.",
        "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not given: %0.",
        "Nie posiadasz uprawnień do modułu w licencji": "You do not have permissions for the module in the license",
        "Nie udało się pobrać listy celów": "Failed to download the target list",
        "Nie udało się pobrać listy działów": "Failed to download the sections list",
        "Nie udało się pobrać listy filtrów": "Failed to download filter list",
        "Nie udało się pobrać listy kanałów": "Failed to download channel list",
        "Nie udało się pobrać listy kolejek": "Failed to download queue list",
        "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
        "Nie udało się pobrać listy ról": "Failed to download role list",
        "Nie udało się pobrać listy serwerów baz danych": "Failed to download the database server list",
        "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
        "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
        "Nie udało się pobrać listy wydziałów": "Failed to download the list of sections",
        "Nie udało się pobrać listy zespołów": "Failed to download the list of teams",
        "Nie udało się pobrać obrazu.": "Failed to download image.",
        "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
        "Nie udało się pobrać ustawień kont użytkowników": "Failed to download user account settings",
        "Nie udało się pobrać widgetu/ów": "Failed to download widget(s)",
        "Nie udało się usunąć szablonu": "Failed to delete template",
        "Nie udało się wykonać akcji": "Failed to perform the action",
        "Nie udało się wysłać pliku": "Failed to send file",
        "Nie udało się załadować widgetu AI.": "The AI ​​widget failed to load.",
        "Nie udało się zapisać filtra": "Failed to save the filter",
        "Nie udało się zapisać szablonu": "Failed to save template",
        "Nie wszystkie towary zostały spakowane, czy napewno chcesz kontynuować?": "Not all goods have been packed, are you sure you want to continue?",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
        "Nie wybrano żadnego użytkownika.": "No user has been selected.",
        "Nie wymaga uwierzytelniania": "No authentication required",
        "nie wymuszaj zmiany hasła": "do not force password change",
        "Nie zdefiniowano żadnych ról.": "No roles are defined.",
        "Nie znaleziono dostępnych widgetów": "No available widgets found",
        "Nie znaleziono filtrów.": "No filters found.",
        "Nie znaleziono formularza.": "The form was not found.",
        "Nie znaleziono kolumn.": "No columns found.",
        "Nie znaleziono pasującego widgetu": "No matching widget found",
        "Nie znaleziono pasujących elementów.": "No matching items found.",
        "Nie znaleziono zapisanych filtrów.": "No saved filters found.",
        "Nie znaleziono żadnego pola.": "No field found.",
        "Nie znaleziono żadnych plików": "No files found",
        "Nie znaleziono żadnych użytkowników.": "No users were found.",
        "Nie znaleziono żadnych wyników.": "No results found.",
        "Nieaktywne": "Inactive",
        "Nieaktywni": "Inactive",
        "Nieaktywny": "Inactive",
        "Niebezpieczeństwo": "Danger",
        "niedozwolone rozszerzenie pliku (%0)": "file extension not allowed (%0)",
        "niedozwolony typ pliku (%0)": "file type not allowed (%0)",
        "Niepoprawne polecenie SQL": "Invalid SQL command",
        "Niepoprawne wyrażenie regularne": "Incorrect regular expression",
        "Niepotwierdzeni": "Unconfirmed",
        "Niepowiązana ": "Unrelated ",
        "Nieprawidłowa definicja": "Incorrect definition",
        "Nieprawidłowy adres email": "Invalid email address",
        "Nieprawidłowy adres URL": "Invalid URL",
        "Nieprzeczytane": "Unread",
        "Niestandardowy błąd": "Non-standard error",
        "Nieudane": "Failed",
        "Nieudane próby logowania": "Failed login attempts",
        "Niewidoczne": "Not visible",
        "Niezgodna": "Incompatible",
        "Nieznane narzędzie": "Unknown tool",
        "Nieznany model": "Unknown model",
        "Nieznany typ pliku": "Unknown file type",
        "Nieznany użytkownik": "Unknown user",
        "Nigdy": "Never",
        "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
        "no-store (wyłącza cache)": "no-store (disables cache)",
        "Normalne": "Normal",
        "Nośnik": "Carrier",
        "Nośnik końcowy": "End carrier",
        "Nośnik początkowy": "Initial carrier",
        "Nośnik zeskanowany pomyślnie.": "Carrier scanned successfully.",
        "Nośnik został dodany": "The carrier has been added",
        "Nośnik został usunięty.": "The carrier has been removed.",
        "Nośnik został zaktualizowany": "The carrier has been updated",
        "Nośnik:": "Carrier:",
        "Nośniki": "Carriers",
        "Nośność": "Load capacity",
        "Nowa data": "New date",
        "Nowe hasło": "New password",
        "Nowe/Oczekuje na przyjęcie": "New/Waiting for receipt",
        "Nowy czat": "New chat",
        "np. (&(objectClass=organizationalUnit))": "e.g. (&(objectClass=organizationalUnit))",
        "np. (&(objectClass=user)": "e.g. (&(objectClass=user)",
        "np. https://api.onesignal.com": "e.g. https://api.onesignal.com",
        "Nr budynku": "Building number",
        "Nr dokumentu": "Document No",
        "Nr dokumentu realizującego": "No. of implementing document",
        "Nr dokumentu wewnętrznego": "Internal document number",
        "Nr dokumentu z ERP": "Document number from ERP",
        "Nr ERP": "ERP No",
        "Nr inwentaryzacji": "Inventory number",
        "Nr kontroli jakości": "No quality control",
        "Nr lokalu": "Apt. no.",
        "Nr rejestracyjny": "Registration no.",
        "Nr tel. os. kontaktowej": "Tel. no. of contact person",
        "Numer": "Number",
        "Numer dokumentu": "Document number",
        "Numer dokumentu WMS": "WMS document number",
        "Numer domu": "House no",
        "Numer listu przewozowego": "Waybill number",
        "Numer mieszkania": "Flat no",
        "Numer oryginalny": "Original number",
        "Numer sesji": "Session number",
        "Numer telefonu": "Phone number",
        "Numeracja": "Numeration",
        "Obiekt %0 został %1": "Object %0 became %1",
        "Objętość [%0]": "Volume [%0]",
        "Obok siebie": "Next to each other",
        "Obraz został pomyślnie opisany": "The image has been successfully described",
        "Obraz został wycięty.": "The image has been cut out.",
        "Obraz został wygenerowany.": "The image has been generated.",
        "Obsługa lokalizacji w WMS": "Location support in WMS",
        "Obsługa nośników w systemie WMS": "Carriers support in the WMS system",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "Supported file formats: txt, md, pdf, docx.",
        "Oczekuje na wysłanie": "Waiting to be sent",
        "od": "from",
        "Od": "From",
        "od {0}": "from {0}",
        "od %0": "from %0",
        "Od lewej do prawej": "From left to right",
        "Od prawej do lewej": "From right to left",
        "Odbiór osobisty": "Individual pick-up",
        "Odblokuj": "Unblock",
        "Odblokuj pole": "Unlock field",
        "Odchylenie standardowe": "Standard deviation",
        "Odejmowanie": "Subtraction",
        "Odinstalowywanie": "Uninstalling",
        "Odpowiedzi": "Responses",
        "Odpowiedź": "Answer",
        "Odpowiedź dowolna": "Any answer",
        "Odrzucone": "Rejected",
        "Odstęp": "Space",
        "Odśwież": "Refresh",
        "Odśwież dane licencyjne": "Refresh your license details",
        "Odśwież listę": "Refresh the list",
        "Odświeżono listę rozszerzeń": "The list of extensions has been refreshed",
        "Odznacz": "Unselect",
        "Odznacz wszystkie": "Unselect all",
        "Ogólne": "General",
        "Ok": "Ok",
        "Opakowanie": "Packaging",
        "Opakowanie zeskanowane pomyślnie": "Package scanned successfully",
        "Opakowanie zostało zaktualizowane": "The packaging has been updated",
        "Opcje": "Options",
        "Opcje dodatkowe": "Additional options",
        "OpenAI": "OpenAI",
        "Operacje": "Operations",
        "Opis": "Description",
        "Opis (ERP)": "Description (ERP)",
        "Opis błędu": "Error description",
        "Opis dla pozycji": "Description for the item",
        "Opis dla pozycji:": "Description for the item:",
        "Opis obrazu": "Image description",
        "Opis zespołu": "Team description",
        "Opis źródła": "Source description",
        "Opisy": "Descriptions",
        "Optymalizuj rozdzielczość obrazów": "Optimize the resolution of your images",
        "Oraz hasło": "And the password",
        "Oraz nowe hasło": "And a new password",
        "Organizacja": "Organization",
        "Organizacja została usunięta.": "The organization has been removed.",
        "Orientacja": "Orientation",
        "Osoba blokująca": "Blocking person",
        "Osoba kontaktowa": "Contact person",
        "Osoba zajmująca stanowisko": "A person holding a station",
        "Osoba zastępowana": "Person being substituted",
        "Osoba zastępująca": "Substitute",
        "Osobista": "Personal",
        "Ostatnia aktywność": "Recent activity",
        "Ostatnia strona": "Last page",
        "Ostrzeżenia": "Warnings",
        "Ostrzeżenie": "Warning",
        "Oś X": "X-axis",
        "Oś Y": "Y axis",
        "Otwórz": "Open",
        "Oznacz jako nieprzeczytane": "Mark as unread",
        "Oznacz jako przeczytane": "Mark as read",
        "Oznacz wszystkie jako przeczytane": "Mark all as read",
        "P. oczekujące": "P. pending",
        "Paczka została przypisana": "The parcel has been assigned",
        "Paczki oczekujące": "Pending parcels",
        "Pakowanie": "Packaging",
        "Pakowanie (Zależy od aktywnego procesu)": "Packing (Depends on active process)",
        "Paleta kolorów": "Color palette",
        "Państwo": "Country",
        "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
        "Partia": "Batch",
        "Partia nie może być pusta": "The batch must not be empty",
        "Partia:": "Batch:",
        "Partie": "Batches",
        "Pasek": "Bar",
        "Pasek postępu": "Progress bar",
        "Październik": "October",
        "Pełny": "Full",
        "Pierwotny dokument": "Original document",
        "Pierwsza strona": "First page",
        "Pierwsze zatowarowanie": "First stocking",
        "Pierwszy miesiąc": "First month",
        "Pigułki": "Pills",
        "Piktogram": "Pictogram",
        "PJM": "PJM",
        "Plan dodany pomyślnie.": "Plan added successfully.",
        "Plan edytowany pomyślnie.": "Plan edited successfully.",
        "Plan sprzedażowy": "Plan sprzedażowy",
        "Plan sprzedażowy na dany miesiąc PJM": "PJM sales plan for a given month",
        "Plan sprzedaży": "Sales plan",
        "Plik": "File",
        "Plik audio": "Audio file",
        "Plik binarny": "Binary file",
        "Plik CSS": "CSS file",
        "Plik CSV": "CSV file",
        "Plik DOC": "DOC file",
        "Plik DOCX": "DOCX file",
        "Plik GIF": "GIF file",
        "Plik HTML": "HTML file",
        "Plik jest wysyłany": "The file is being sent",
        "Plik JPEG": "JPEG file",
        "Plik JPG": "JPG file",
        "Plik MP4": "MP4 file",
        "Plik PNG": "PNG file",
        "Plik skompresowany": "Compressed file",
        "Plik tekstowy json": "Json text file",
        "Plik tekstowy txt": "Txt text file",
        "Plik ZIP": "ZIP file",
        "Plik został dodany.": "The file has been added.",
        "Plik został usunięty.": "The file has been deleted.",
        "Plik został wysłany": "The file has been sent",
        "Pliki": "Files",
        "Pliki Asystent": "Files Assistant",
        "Pliki Asystenta": "Assistant Files",
        "Pliki asystenta AI": "AI assistant files",
        "Pliki do wysłania: %0": "Files to send: %0",
        "Pliki prywatne": "Private files",
        "Pliki publiczne": "Public files",
        "Pliki współdzielone": "Shared files",
        "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
        "Po jednym synonimie w wierszu": "One synonym per line",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "If you select this option, user accounts will be deleted from the system. Do you want to continue?",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
        "Po zmianie konfiguracji koniecznie jest pełne odświeżenie strony. Klienci którzy połączyli się z poprzednią konfiguracją mogą mieć problemy z połączeniem do nowej. Zeleca się wyczyszczenie pamięci strony.": "After changing the configuration, it is necessary to completely refresh the page. Clients that connected to the previous configuration may have problems connecting to the new one. It is recommended to clear the page memory.",
        "Pobierz": "Download",
        "Pobierz z": "Take from",
        "Poczta": "Mail",
        "Podaj kod dostępu do stanowiska pakowania": "Enter the access code to the packing station",
        "Podaj kod weryfikacyjny": "Enter verification code",
        "Podaj lokalizację kuriera": "Enter the location of the courier",
        "Podaj lokalizację pakowacza": "Specify the location of the packer",
        "Podaj nową nazwę": "Enter a new name",
        "Podaj swój adres email": "Enter your email address",
        "Podana nazwa jest zajęta.": "The name you entered is already taken.",
        "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
        "Podanie nazwy jest wymagane.": "Name is required.",
        "Podano adres": "The address provided is",
        "Podano nieprawidłowe dane": "Invalid data provided",
        "Podano nieprawidłowe dane.": "Invalid data provided.",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "During the first startup, it is required to create a master administrator account.",
        "Podgląd": "Preview",
        "Podgląd formularza": "Form preview",
        "Podgląd jest dostępny jedynie po dodaniu treści.": "The preview is available only after adding content.",
        "Podgląd lokalizacji": "Location preview",
        "Podgląd lokalizacji dla produktu: %0": "Location preview for product: %0",
        "Podgląd tabeli": "Table preview",
        "Podgląd zawartości": "Content preview",
        "Podkontrahent": "Subcontractor",
        "Podkreślenie": "Highlight",
        "Podpis": "Signature",
        "Podpowiedzi lokalizacji": "Location suggestions",
        "Podstawowa jednostka miary": "Basic unit of measurement",
        "Podstawowe": "Basic",
        "Podstawowe informacje": "Basic information",
        "Podstawowe operacje na datach": "Basic date operations",
        "Podstawowe operacje na liczbach": "Basic digits operations",
        "Podstawowe operacje tekstowe": "Basic text operations",
        "Podstawowe operacje warunkowe": "Basic conditional operations",
        "Podstawowy": "Basic",
        "Podziedziałek - Piątek": "Monday - Friday",
        "Pojedynczo": "Individually",
        "Pojedynczy wybór": "Single choice",
        "Pokaż": "Show",
        "Pokaż dostawy": "Show deliveries",
        "Pokaż etykietę": "Show tag",
        "Pokaż historię": "Show history",
        "Pokaż ilość": "Show quantity",
        "Pokaż lokalizację": "Show location",
        "Pokaż mniej": "Show less",
        "Pokaż nośnik": "Show carrier",
        "Pokaż nośnik końcowy": "Show end carrier",
        "Pokaż nośnik początkowy": "Show initial carrier",
        "Pokaż numery stron": "Show page numbers",
        "Pokaż partię": "Show batch",
        "Pokaż powiązane dokumenty": "Show related documents",
        "Pokaż produkty, których wystarczy na mniej niż": "Show products that last less than",
        "Pokaż produkty, których wystarczy na więcej niż": "Show products that are enough for more than",
        "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
        "Pokaż stany niezerowe": "Show non-zero states",
        "Pokaż szczegóły": "Show details",
        "Pokaż tylko liderów": "Show only leaders",
        "Pokaż tylko moje dokumenty": "Show only my documents",
        "Pokaż tytuł formularza": "Show form title",
        "Pokaż tytuł sekcji": "Show section title",
        "Pokaż tytuł strony": "Show page title",
        "Pokaż tytuły stron": "Show page titles",
        "Pokaż w koncie użytkownika": "Show in user account",
        "Pokaż więcej": "Show more",
        "pokaż więcej...": "show more...",
        "Pokaż wszystkie": "Show all",
        "Pokaż wszystkie plany": "Show all plans",
        "Pokaż wykres": "Show chart",
        "Pokaż z rezerwacjami": "Show with reservations",
        "Pokaż zdjęcie": "Show photo",
        "Pola dokumentu": "Document fields",
        "Pola własne": "Own fields",
        "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "The field \"%0\" contains an illegal value.",
        "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
        "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
        "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
        "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
        "Pole dokumentu": "Pole dokumentu",
        "Pole jest wymagane": "The field is required",
        "Pole może zawierać jedynie cyfry.": "Field can only contain numbers.",
        "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and numbers.",
        "Pole może zawierać jedynie litery.": "This field can only contain letters.",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "The field can only contain the body of a method validating data in the form of native JavaScript code.",
        "Pole nie może zawierać spacji": "The field must not contain spaces",
        "Pole skopiowane": "Field copied",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "Field type \"%0\" is not allowed in this form.",
        "Pole wymagane": "Field required",
        "Pole zawiera": "Field contains",
        "Polityka prywatności": "Privacy policy",
        "Polski": "Polish",
        "Pomoc": "Help",
        "Pomyślne odświeżono dane licencyjne": "Successfully refreshed license data",
        "Pomyślne przypisano aplikację do klucza licencyjnego": "The application has been successfully assigned a license key",
        "Pomyślnie anulowano dokument.": "Successfully canceled the document.",
        "Pomyślnie przekazano na magazyn dokument.": "The document was successfully transferred to the warehouse.",
        "Pomyślnie usunięto przesyłkę": "Successfully removed shipment",
        "Pomyślnie zakończono inwentaryzację cząstkową.": "A partial inventory was successfully completed.",
        "Pomyślnie zakończono kontrolę.": "Check completed successfully.",
        "Pomyślnie zakończono pakowanie.": "Packaging has been successfully completed.",
        "Pomyślnie zakończono przesunięcie.": "The transfer was successfully completed.",
        "Pomyślnie zakończono przyjęcie.": "The receipt was successfully completed.",
        "Pomyślnie zakończono wydanie.": "Successfully completed the issue.",
        "Pomyślnie zakończono zbieranie.": "The collection was successfully completed.",
        "Pomyślnie zakończono zwrot.": "The return was successfully completed.",
        "Pomyślnie zaktualizowano kolejność lokalizacji": "Successfully updated the order of locations",
        "Pomyślnie zaktualizowano kolejność rzędów": "Successfully updated the order of rows",
        "Pomyślnie zaktualizowano kolejność stref.": "Successfully updated the order of the zones.",
        "Pomyślnie zaktualizowano wykluczenie.": "Successfully updated the exclusion.",
        "Pomyślnie zapisano zmiany.": "Successfully saved the changes.",
        "Pomyślnie zeskanowano paczkę": "Successfully scanned the package",
        "Pomyślnie zlecono zakończenie inwentaryzacji zbiorczej.": "Successfully commissioned the completion of the summary inventory.",
        "Ponowiono zadanie.": "The task has been renewed.",
        "Ponów": "Redo",
        "Poprawne": "Correct",
        "Poprawnie zeskanowano pozostałe paczki.": "The remaining packages were scanned correctly.",
        "Poprawny": "Correct",
        "Poprzednia strona": "Previous page",
        "Porównaj %0": "Compare %0",
        "Porównaj z edytowaną wersją": "Compare with edited version",
        "Porównania": "Comparison",
        "Porównanie": "Comparison",
        "Porównywana wersja": "Compared version",
        "Port": "Port",
        "Posiada załączniki": "It has attachments",
        "Post": "Post",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "POST: List of entries (any search)",
        "POST: Upload załączników": "POST: Upload attachments",
        "POST: Utworzenie wpisu": "POST: Create an entry",
        "POST: Zapisanie zmian": "POST: Save changes",
        "Postęp zamykania inwentaryzacji": "Inventory closure progress",
        "Potwierdzam": "Confirm",
        "Potwierdzenia": "Confirmations",
        "Potwierdzenie": "Confirmation of status change",
        "Potwierdzenie anulowania": "Confirmation of cancellation",
        "Potwierdzenie przeinstalowania": "Confirmation of reinstallation",
        "Potwierdzenie przekazania": "Transfer confirmation",
        "Potwierdzenie przywrócenia": "Confirmation of restoration",
        "Potwierdzenie usunięcia": "Deletion confirmation",
        "Potwierdzenie usunięcia lokalizacji {0}": "Confirmation of location removal {0}",
        "Potwierdzenie usunięcia poziomu {0}": "Confirmation of level removal {0}",
        "Potwierdzenie usunięcia przęsła {0}": "Confirmation of span removal {0}",
        "Potwierdzenie wylogowania użytkownika": "Confirmation of user logout",
        "Potwierdzenie zakończenia": "Confirmation of completion",
        "Potwierdzenie zmiany statusu": "Confirmation of status change",
        "Potwierdź": "Confirm",
        "Potwierdź akceptację": "Confirm acceptance",
        "Potwierdź usunięciennOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany element?": "Confirm deletionnOperation is irreversible. Are you sure you want to delete the selected item?",
        "Powiadomienia": "Notifications",
        "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot stop this notification to appear.",
        "Powiadomienie testowe zostało wysłane.": "The test notification has been sent.",
        "Powiązana": "Related",
        "Powiązanie stref z magazynem ERP": "Linking zones to the ERP warehouse",
        "Powiększ": "Zoom in",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "A PRIVATE directive should be added for user-personalized content, especially for responses received after login and with permissions.",
        "powrót": "back",
        "Powrót": "Back",
        "Powtórz hasło": "Repeat password",
        "Powtórzone": "Repeated",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "The above objects contain only properties and are stripped of all methods.",
        "Poziom": "Level",
        "Poziom dostępu do webhooka": "Webhook access level",
        "Poziom dostępu użytkownika": "User access level",
        "Poziom logowania błedów": "Poziom logowania błedów",
        "Poziom został dodany.": "The level has been added.",
        "Poziom został usunięty.": "The level has been removed.",
        "Poziomy logowania": "Login levels",
        "Pozostało %0 znaków.": "There are %0 characters left.",
        "Pozycja": "Position",
        "Pozycja edytowana pomyślnie.": "Position edited successfully.",
        "Pozycja o takich parametrach już istnieje.": "An item with such parameters already exists.",
        "Pozycja została dodana.": "The item has been added.",
        "Pozycja została usunięta.": "The item has been deleted.",
        "Pozycje rozłożone poprawnie.": "The items unfolded correctly.",
        "Pozycje zostały dodana.": "The items have been added.",
        "pół roku": "six months",
        "Półmiesięczny": "Semi-monthly",
        "Prawy panel": "Right panel",
        "Predefiniowane lokalizacje dla produktu": "Predefined locations for the product",
        "Predefiniowane tła panelu z aplikacjami": "Predefined backgrounds of the panel with applications",
        "Predefiniowane zestawy kolorów": "Predefined color sets",
        "Priorytet": "Priority",
        "Priorytet kolejki": "Queue priority",
        "private (tylko cache przeglądarki)": "private (browser cache only)",
        "Procent": "Percentage",
        "Proces został zaktualizowany.": "The process has been updated.",
        "Procesy": "Processes",
        "Produkt": "Product",
        "Produkt %0 - %1 został już dodany z inną jednostką miary": "Product %0 - %1 has already been added with a different unit of measurement",
        "Produkt został dodany.": "The product has been added.",
        "Produkt został przyjęty.": "The product has been accepted.",
        "Produkt został usunięty.": "The product has been removed.",
        "Produkt został wydany.": "The product has been issued.",
        "Produkt został zaktualizowany.": "The product has been updated.",
        "Produkty": "Products",
        "Profil użytkownika": "Profile",
        "Profil został zaktualizowany.": "The profile has been updated.",
        "Projekt": "Project",
        "Prometheus": "Prometheus",
        "Proporcje obrazu": "Image aspect ratio",
        "Proste uprawnienia": "Simple permissions",
        "Próbkowanie": "Sampling",
        "Prywatne": "Private",
        "Przebudowanie indeksów": "Rebuilding the indexes",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "Rebuilding indexesnnOperation can take from a few seconds to several hours depending on the size of the indexes.nnUse only if you need to apply changes made to the dictionaries of synonyms or excluded words.",
        "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
        "Przeczytane": "Read",
        "Przedrostek": "Prefix",
        "Przeindeksowanie danych": "Data over-indexing",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "Over-indexing datannOperation can take from a few seconds to a few hours depending on the amount of data in the database.nnUse only if you notice missing data in the indexes.",
        "Przeinstalowano poprawnie": "Reinstalled correctly",
        "Przeinstaluj": "Reinstall",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "Go to setup to enable the tools",
        "Przekazano": "Passed",
        "Przekazano do magazynu poprawnie": "Transferred to warehouse correctly",
        "Przekaż do magazynu": "Transfer to warehouse",
        "Przekaż na magazyn": "Transfer to warehouse",
        "Przekroczono dozwoloną ilość znaków: %0.": "Allowed number of characters exceeded: %0.",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "exceeded the allowed file size (%0 MB)",
        "Przełącznik": "Switcher",
        "Przeniesienie wybranych elementów": "Transfer selected items",
        "przeniesiony do kosza": "transferred to basket",
        "Przenieś": "Move",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "Move${selected.length > 1 ? ' selected : ' }",
        "przeniósł do kosza": "transferred to trash",
        "Przepisz aktualne stawki za 1000 tokenów z cennika OpenAI.": "Enter the current rates for 1,000 tokens from the OpenAI price list.",
        "Przepisz limit konta OpenAI.": "Rewrite your OpenAI account limit.",
        "Przepnij alias": "Rewrite alias",
        "Przerwano": "Interrupted",
        "Przerwano kontrolę.": "The inspection was interrupted.",
        "Przerwano pracę nad dokumentem.": "Work on the document was interrupted.",
        "Przerwano pracę nad wydaniem.": "Work on the issue was interrupted.",
        "Przerwij": "Interrupt",
        "Przesunięcia": "Transfers",
        "Przesunięcie": "Transfer",
        "Przesunięcie zakończone.": "Transfer completed.",
        "Przesuń": "Move",
        "Przesuń do góry": "Move up",
        "Przesuń na dół": "Move down",
        "Przesuń towary": "Move the goods",
        "Przesuń w lewo": "Swipe left",
        "Przesuń w prawo": "Swipe right",
        "Przesyłanie plików powiodło się.": "File transfer was successful.",
        "Przetestuj": "Test it",
        "Przetestuj poprawność zapisanych kluczy": "Test the correctness of the stored keys",
        "Przetestuj ustawienia poczty": "Test your mail settings",
        "Przetestuj ustawienia Sms": "Test Sms settings",
        "Przetwarzane": "Processing",
        "Przęsło": "Span",
        "Przęsło ": "Span ",
        "Przęsło zostało dodane.": "The span has been added.",
        "Przęsło zostało usunięte.": "The span has been removed.",
        "Przybornik": "Kit",
        "Przycisk": "Button",
        "Przyjęcia": "Receipt",
        "Przyjęcia awizowane": "Notified receipts",
        "Przyjęcia awizowane (Akceptacje)": "Notified admissions (Acceptances)",
        "Przyjęcia bezpośrednie": "Direct receipts",
        "Przyjęcia bezpośrednie (Akceptacje)": "Direct acceptances (Acceptances)",
        "Przyjęcie": "Receipt",
        "Przyjęcie (inwentaryzacja)": "Receipt (inventory)",
        "Przyjęcie bezpośrednie zostało dodane": "Direct receipt been added",
        "Przyjmij": "Accept",
        "Przyjmij/wydaj": "Receive/issue",
        "Przykład": "Example",
        "Przykład:": "Example:",
        "Przykład: domena\\użytkownik": "Example: domain user",
        "Przykład: użytkownik@domena": "Example: user@domain",
        "Przykładowa struktura danych": "Sample data structure",
        "Przykłady": "Examples",
        "Przypisanie do zespołu": "Assignment to a team",
        "Przypisanie ról": "Assignment of roles",
        "Przypisz": "Assign",
        "Przypisz aplikację": "Assign an application",
        "Przypisz opakowanie": "Assign packaging",
        "Przyrostek": "Suffix",
        "Przywrócenie": "Restore",
        "przywrócił z kosza": "brought back from the garbage can",
        "przywrócony z kosza": "restored from the garbage can",
        "Przywróć": "Reload",
        "Przywróć domyślne": "Restore defaults",
        "Przywróć filtr": "Restore filter",
        "Przywróć ustawienia domyślne reguł i celów": "Przywróć ustawienia domyślne reguł i celów",
        "Przyznaj dostęp": "Grant access",
        "public (cache przeglądarki i proxy)": "public (browser cache and proxy)",
        "Publiczne": "Public",
        "Pusty rząd": "Empty row",
        "Put": "Put",
        "PUT: Aktualizacja wpisu": "PUT: Update entry",
        "PW": "PW",
        "PW – przyjęcie wewnętrzne": "PW - internal receipt",
        "Pytania": "Questions",
        "PZ – przyjęcie zewnętrzne": "PZ - external receipt",
        "QR ": "QR ",
        "Query string": "Query string",
        "Radio": "Radio",
        "Ranga": "Rank",
        "Raport": "Report",
        "Raport sprzedażowy": "Raport sprzedażowy",
        "Raport sprzedaży": "Sales report",
        "Raport XYZ": "XYZ report",
        "Raport został dodany.": "The report added.",
        "Raport został zaktualizowany.": "The report has been updated.",
        "Raporty": "Reports",
        "Raporty AI": "AI reports",
        "Raporty systemowe": "System reports",
        "Realizacja": "Realization",
        "Realizacja zlecenia przesunięcia": "Implementation of the transfer order",
        "ReCaptcha": "ReCaptcha",
        "Region": "Region",
        "Reguła": "Rule",
        "Reguła filtracyjna": "Filter rule",
        "Reguła końcowa": "Final rule",
        "Reguła został dodana.": "The rule has been added.",
        "reguła została usunięta.": "the rule has been deleted.",
        "Reguła została zaktualizowana.": "The rule has been updated.",
        "Reguły": "Rules",
        "Rejestr zmian": "Changelog",
        "Relacyjne": "Relational",
        "Repozytorium plików": "File repository",
        "Request body": "Request body",
        "Resetuj": "Reset",
        "Resetuj układ": "Reset Layout",
        "REST": "REST",
        "Rezerwacja": "Reservation",
        "Rezerwacje": "Reservations",
        "Rezerwacje kontroli jakości": "Quality control reservations",
        "Rodzaj": "Type",
        "Rodzaj etykiety": "Type of label",
        "Rodzaj lokalizacji": "Type of location",
        "Rodzaj nośnika": "Carrier type",
        "Rodzaj nośnika został dodany": "The carrier type has been added",
        "Rodzaj nośnika został usunięty.": "The carrier type has been deleted.",
        "Rodzaj nośnika został zaktualizowany": "The carrier type has been updated",
        "Rodzaj odpowiedzi": "Type of response",
        "Rodzaj przesyłki": "Type of shipment",
        "Rodzaj użytego narzędzia do wygenerowanej treści.": "The type of tool used for the generated content.",
        "Rodzaje lokalizacji": "Types of locations",
        "rok": "year",
        "Rok": "Year",
        "Rola": "Role",
        "Rola aktywna": "Active role",
        "Rola domyślna": "Default role",
        "Rola została dodana.": "The role has been added.",
        "Rola została usunięta.": "The role has been removed.",
        "Rola została zaktualizowana.": "The role has been updated.",
        "Role i uprawnienia": "Roles and permissions",
        "Role użytkownika": "User roles",
        "Role zostały przypisane": "Roles have been assigned",
        "Root": "Root",
        "Rozdzielczość": "Resolution",
        "Rozdzielczość wygnerowanego obrazu.": "Resolution of the generated image.",
        "Rozkładanie": "Unfolding",
        "Rozłożono pomyślnie": "Successfully unfolded",
        "Rozłóż towary": "Lay out the goods",
        "Rozmiar": "Size",
        "Rozmiar okna popup": "Popup window size",
        "Rozmiar pliku": "File size",
        "Rozmiar wydruku": "Print size",
        "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
        "Rozmieść równomiernie": "Distribute evenly",
        "Rozpoczęto kontrolę.": "The inspection has started.",
        "Rozpoczęto pracę nad dokumentem.": "Work on the document has begun.",
        "Rozpoczęto pracę nad wydaniem.": "Work has begun on the issue.",
        "Rozpocznij": "Start",
        "Rozpocznij kontrolę": "Start checking",
        "Rozpocznij pakowanie": "Start packing",
        "Rozpocznij przesunięcie": "Start shifting",
        "Rozpocznij załadunek": "Start loading",
        "Rozpocznij zbieranie": "Start collecting",
        "Rozszerzony - błedy ważne": "Extended - important errors",
        "Równość": "Equality",
        "RW": "RW",
        "RW – rozchód wewnętrzny": "RW – internal expenditure",
        "Rząd": "Row",
        "Rząd został dodany.": "The row was added.",
        "Rzeczywista wartość sprzedaży": "Actual sales value",
        "Rzędy": "Rows",
        "Satysfakcja": "Satisfaction",
        "Schemat kodu lokalizacji": "Location code scheme",
        "Schemat kodu QR": "QR code scheme",
        "Sekcja": "Section",
        "Sentry": "Sentry",
        "Separator": "Divider",
        "Server Side Cache": "Server Side Cache",
        "Serwer bazy danych": "Database server",
        "Serwer dostępny": "Server available",
        "Serwer plików": "File server",
        "Serwer został dodany.": "Server has been added.",
        "Serwer został usunięty.": "Server has been removed.",
        "Serwer został zaktualizowany.": "Server has been updated.",
        "Serwery plików": "File servers",
        "Sesja": "Session",
        "Sesja nr %0": "Session No . %0",
        "Sesja użytkownika": "User session",
        "Sierpień": "August",
        "Skala 1-5": "Scale 1-5",
        "Skaner": "Scanner",
        "Skanowanie": "Scanning",
        "Sklep": "Available",
        "Skontrolowano produkty.": "The products were inspected.",
        "Skrót został dodany": "The shortcut has been added",
        "Skrót został usunięty": "The shortcut has been removed",
        "słowa": "words",
        "Słownik": "Dictionary",
        "Słownik dowolny": "Any dictionary",
        "Słownik systemowy": "System dictionary",
        "Słowo": "Word",
        "Słowo bazowe": "Base word",
        "Słowo wykluczone": "The word excluded",
        "Słowo zostało dodane.": "Word has been added.",
        "Słowo zostało usunięte.": "The word has been removed.",
        "Słowo zostało zaktualizowane.": "Word has been updated.",
        "Sortowanie malejąco": "Sort descending",
        "Sortowanie rosnąco": "Sort ascending",
        "Spakuj wszystkie": "Pack all",
        "Specjalizowane": "Specialized",
        "Specyfikacja": "Specification",
        "Spełnia kryteria do kontroli jakości": "Meets the criteria for quality control",
        "Sposób dostawy": "Delivery method",
        "Sposób prezentacji zakładek": "The way bookmarks are presented",
        "Sposób przeliczania zajętości lokalizacji": "Method of calculating site occupancy",
        "Sprawdzenie czy pusty": "Check if empty",
        "Sprawdzono:": "Checked:",
        "Sprawdź": "Check",
        "Sprawdź ilość:": "Check quantity:",
        "Sprawdź zgodność:": "Check compatibility:",
        "Sprzedaż z okresu (dni)": "Sales per period (days)",
        "SQL": "SQL",
        "Stała wartość": "Constant value",
        "Stan": "Satus",
        "Stan minimalny": "Minimal condition",
        "Stan powiadomienia": "Notification status",
        "Stan przed": "The state before",
        "Standardowa szczegółowość": "Standard detail",
        "Standardowe uprawnienia": "Standard entitlements",
        "Stanowisko": "Station",
        "Stanowisko zostało zwolnione": "The station has become vacant",
        "Stany ERP": "ERP states",
        "Start": "Start",
        "Status": "Status",
        "Status dokumentu został zmieniony.": "The status of the document has been changed.",
        "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
        "Status formularza został zmieniony.": "The status of the form has been changed.",
        "Status modułu": "Module status",
        "Status webhooka": "Webhook status",
        "Stopka": "Footer",
        "Strefa": "Zone",
        "Strefa została dodana.": "The zone has been added.",
        "Strefa została usunięta.": "The zone has been removed.",
        "Strefa została zaktualizowana.": "The zone has been updated.",
        "Strefy": "Zones",
        "Strona": "Page",
        "Strona %0": "Page %0",
        "Strona główna": "Home",
        "Struktura organizacyjna": "Organisational structure",
        "Stwierdzenie": "Statement",
        "Styczeń": "January",
        "Styl obrazu": "Image style",
        "Sugerowana lokalizacja": "Suggested location",
        "Sugerowane nośniki": "Suggested carriers",
        "Sukces": "Success",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "The sum of input and output given in tokens.",
        "Suma na lokalizacji": "Total per location",
        "Suma na magazynie": "Total in stock",
        "Suma obj. zamówienia": "Total volume of the order",
        "Suma sprzedaży": "Total sales",
        "Suma tokenów": "Total tokens",
        "Suma wagi zamówienia": "Total order weight",
        "Symbol": "Symbol",
        "Symbol jest wymagany": "The symbol is required",
        "Symbol jest zajęty": "The symbol is occupied",
        "Symbol klienta": "Customer symbol",
        "Symbol musi mieć co najmniej 3 znaki": "The symbol must have at least 3 characters",
        "Symbol musi mieć co najmniej 5 znaków": "The symbol must have at least 5 characters",
        "Symbol nie może być dłuższy niż 10 znaków": "The symbol must not be longer than 10 characters",
        "Symbol nie może być dłuższy niż 20 znaków": "The symbol must not be longer than 20 characters",
        "Symbol produktu": "Product symbol",
        "Symbol w adresie URL": "Symbol in the URL",
        "Symbol:": "Symbol:",
        "Symbol/Nazwa/EAN": "Symbol/Name/EAN",
        "Synonimy": "Synonyms",
        "Synonimy zostały dodane.": "Synonyms have been added.",
        "Synonimy zostały usunięte.": "Synonyms have been removed.",
        "Synonimy zostały zaktualizowane.": "Synonyms have been updated.",
        "System": "System",
        "Szablon modułu": "Module template",
        "Szablon został dodany.": "The template has been added.",
        "Szablon został usunięty.": "The template has been removed.",
        "Szablon został zaktualizowany.": "The template has been updated.",
        "Szablony etykiet": "Label templates",
        "Szacowana kwota:": "Estimated amount:",
        "Szczególy": "Details",
        "Szczegółowe": "Specific",
        "Szczegółowość obrazu": "Image detail",
        "Szczegółowy - błedy krytyczne": "Detailed - critical errors",
        "Szczegóły": "Details",
        "Szczegóły asystenta": "Assistant details",
        "Szczegóły lokalizacji": "Location details",
        "Szczegóły pliku": "File details",
        "Szczegóły procesu": "Details of the process",
        "Szczegóły zadania": "Task details",
        "Szczegóły zapytania": "Inquiry details",
        "Szczegóły zdarzenia": "Event details",
        "Szczegóły zdarzenia nr %0": "Details of event number %0",
        "Szerokość": "Width",
        "Szerokość (%0)": "Width (%0)",
        "Szerokość (mm)": "Width (mm)",
        "Szerokość [%0]": "Width [%0]",
        "Szerokość strony": "Page width",
        "Szkic": "Draft",
        "szt.": "pcs.",
        "Sztuczna inteligencja": "Artificial intelligence",
        "Szukaj": "Search",
        "Szukaj we wszystkich wersjach": "Search in all versions",
        "Szukana fraza": "Search term",
        "Szukane słowo": "Search word",
        "Szybkie przesunięcia": "Quick Transfers",
        "Szyfruj": "Encrypt",
        "Średni": "Medium",
        "Średnia arytmetyczna": "Arithmetic average",
        "Średnia sprzedaż z X dni": "Average sales for X days",
        "Środowisko": "Environment",
        "Ta strona jest chroniona przez reCAPTCHA i Google.": "This site is protected by reCAPTCHA and Google.",
        "Tabela": "Table",
        "Tagi": "Tags",
        "Tak": "Yes",
        "TAK": "YES",
        "Tak/Nie": "Yes/No",
        "Tekst": "Text",
        "Tekst alternatywny": "Alt text",
        "Tekst zastępczy": "Alternative text",
        "Telefon": "Phone",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "This dashboard does not contain any widgets yet",
        "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
        "Ten produkt został zeskanowany w innym dokumencie inwentaryzacyjnym.": "This product was scanned in another inventory document.",
        "Teraz": "Now",
        "Termin dostawy": "Delivery date",
        "Termin kolejnej dostawy": "Next delivery date",
        "Termin realizacji": "Implementation date",
        "Termin relizacji": "Relization date",
        "Termin relizacji:": "Relization date:",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
        "Test konfiguracji": "Configuration test",
        "Test ustawień pomyślnie zakończony.": "Settings test successfully completed.",
        "Testowy adres email": "Tested email address",
        "Testowy numer telefonu": "Test phone number",
        "Testuj": "Test",
        "Testuj zapytanie": "Test Query",
        "Tłumaczenia": "Translations",
        "To pole jest wymagane": "The field is required",
        "Token": "Token",
        "Token dostępu do konta. Znajduje się w zakładce `Keys & IDs`": "Account access token. It is located in the `Keys & IDs` tab",
        "Token PAT": "PAT Token",
        "Token został usunięty.": "The token has been removed.",
        "Token został zapisany.": "The token has been saved.",
        "Tokeny": "Tokens",
        "Tokeny wejściowe": "Input tokens",
        "Tokeny wyjściowe": "Output tokens",
        "Towar": "Merchandise",
        "Towar zamówiony": "Goods ordered",
        "Towary": "Goods",
        "Trace - najwięcej szczegółów": "Trace - most details",
        "Tracing": "Tracing",
        "Treści odpowiedzi są wymagane": "Answers are required",
        "Treści pytań są wymagane": "Questions are required",
        "Treść": "Content",
        "Treść błędu:.": "Contents of the error:.",
        "Treść szablonu": "Content of the template",
        "Trwa generowanie dokumentu ...": "The document is being generated...",
        "Trwa generowanie obrazów": "Images are being generated",
        "Trwa generowanie obrazu": "Image generation in progress",
        "Trwa generowanie opisu dla wybranego obrazu": "Description generation for the selected image is in progress",
        "Trwa generowanie zapytania. Proszę czekać": "The query is being generated. Please wait",
        "Trwa pobieranie PDF ...": "PDF downloading...",
        "Trwa sprawdzanie statusu": "Status check in progress",
        "Tryb konserwacji systemu": "System maintenance mode",
        "Trzy kolumny": "Three columns",
        "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
        "Tworzenie dokumentów": "Document creation",
        "Tworzenie inwentaryzacji zbiorczej": "Creating a bulk inventory",
        "Tworzenie przyjęcia bezpośredniego": "Creating a direct receipt",
        "Tworzenie wydania bezpośredniego": "Creating a direct issue",
        "Tworzenie zlecenia przesunięcia": "Creating a transfer order",
        "Twój token": "Your token",
        "tydzień": "week",
        "Tydzień": "Week",
        "Tylko aktywne źródła": "Only active sources",
        "Tylko do odczytu": "Read-only",
        "tylko dokumenty wersjonowane": "versioned documents only",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
        "Typ": "Type",
        "Typ inwentaryzacji": "Inventory type",
        "Typ kolumny": "Column type",
        "Typ konta": "Account type",
        "Typ logowania": "Login type",
        "Typ mime": "Mime type",
        "Typ nośnika": "Carrier type",
        "Typ obiektu": "Object type",
        "Typ pliku": "File type",
        "Typ pola": "Field type",
        "Typ powiadomienia": "Notification type",
        "Typ przyjęcia": "Type of receipt",
        "Typ ruchu": "Type of movement",
        "Typ serwera": "Server type",
        "Typ słownika": "Dictionary type",
        "Typ właściwy": "The right type",
        "Typ wydania": "Issue type",
        "Typ zapytania": "Query type",
        "Typ został dodany.": "Type has been added.",
        "Typ został usunięty.": "The type has been removed.",
        "Typ został zaktualizowany.": "The type has been updated.",
        "Typ źródła": "Source type",
        "Typ źródła danych": "Data source type",
        "typu \"%0\" o ID \"%1\"": "of type \"%0\" with ID \"%1\"",
        "Typy stref": "Zone types",
        "Tytuł": "Title",
        "Tytuł formularza jest wymagany": "Form title is required",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 znaków",
        "Tytuł sekcji": "Section title",
        "Udało się zapisać ustawienia widgetów": "Successfully saved widget settings",
        "Układ": "Arrangement",
        "Układ listy": "List layout",
        "Układ zapisany poprawnie.": "Layout written correctly.",
        "Ukryj ilość": "Hide quantity",
        "Ukryj lokalizację": "Hide location",
        "Ukryj nośnik": "Hide the carrier",
        "Ukryj nośnik końcowy": "Hide the end carrier",
        "Ukryj nośnik początkowy": "Hide initial carrier",
        "Ukryj partię": "Hide the lot",
        "Ukryta": "Hidden",
        "Ukryty": "Hidden",
        "Ulica": "Street",
        "Umieszczenie towarów w lokalizacji": "Placement of goods in location",
        "Upload": "Upload",
        "Uprawnienia": "Permissions",
        "Uprawnienia do dokumentów": "Entitlement to documents",
        "Url": "Invalid URL",
        "Uruchom": "Run",
        "Uruchom zadanie": "Run the task",
        "Urządzenie zostało usunięte.": "The device has been removed.",
        "Usługi": "Services",
        "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
        "Ustawienia": "Settings",
        "Ustawienia konta": "Account settings",
        "Ustawienia nośników": "Carrier settings",
        "Ustawienia powiadomień PUSH": "PUSH notification settings",
        "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
        "Ustawienia sesji": "Session settings",
        "ustawienia widgetu": "widget settings",
        "Ustawienia zaawansowane": "Advanced settings",
        "Ustawienia zostały zapisane": "Your settings have been saved",
        "Ustawienia zostały zapisane.": "Settings have been saved.",
        "usunął": "deleted",
        "Usunął": "Deleted",
        "Usunięcie": "Deletion",
        "Usunięcie dokumentu": "Document deletion",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "Deleting a formThe operation is irreversible. All related documents will be lost!nnAre you sure you want to delete the selected form?",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "Delete indexunnOperation is irreversible. Are you sure you want to delete the selected index?",
        "Usunięcie kategorii spowoduje również usunięcie wszystkich powiązanych z nią formularzy.nnCzy na pewno kontynuować?": "Deleting a category will also delete all associated forms.nnAre you sure to continue?",
        "Usunięcie wybranych elementów": "Delete selected items",
        "Usunięto dokument z agregacji.": "Removed document from aggregation.",
        "Usunięto dokument.": "Document removed.",
        "Usunięto paczkę z załadunku.": "A package was removed from the load.",
        "usunięty": "removed",
        "Usuń": "Delete",
        "Usuń datę": "Delete date",
        "Usuń dział": "Delete department",
        "Usuń filtr": "Remove filter",
        "Usuń grupę": "Delete the group",
        "Usuń indeks": "Delete index",
        "Usuń kategorię": "Delete category",
        "Usuń kolejkę": "Delete the queue",
        "Usuń lokalizacje {0}": "Delete locations {0}",
        "Usuń lokalizację": "Delete location",
        "Usuń plik": "Delete file",
        "Usuń plik z listy": "Remove the file from the list",
        "Usuń poziom {0}": "Delete level {0}",
        "Usuń pozycje": "Delete items",
        "Usuń pozycję": "Delete item",
        "Usuń produkt": "Remove product",
        "Usuń przęsło {0}": "Remove span {0}",
        "Usuń sekcję": "Delete a section",
        "Usuń skrót": "Delete shortcut",
        "Usuń spacje": "Remove spaces",
        "Usuń stanowisko": "Delete a station",
        "Usuń urządzenie": "Remove device",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "Remove device to cancel remembered automatic logins to your account",
        "Usuń warunek": "Remove the condition",
        "Usuń z zespołu": "Remove from team",
        "Usuń zdjęcie": "Delete photo",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "Delete${selected.length > 1 ? ' selected : ''}",
        "Usuwanie dokumentów": "Deletion of documents",
        "Usuwanie użytkowników": "Deleting users",
        "Usuwanie własnych dokumentów": "Deleting your own documents",
        "Usuwanie wszystkich dokumentów": "Deleting all documents",
        "Utworzył": "Created",
        "Utwórz": "Create",
        "Utwórz grupy": "Create groups",
        "Utwórz katalog": "Create a directory",
        "Utwórz kopię formularza": "Create a copy of the form",
        "Utwórz kopię z": "Create a copy from",
        "Utwórz nową inwentaryzację zbiorczą": "Create a new bulk inventory",
        "Utwórz nowy": "Create new",
        "Utwórz nowy wątek": "Create a new thread",
        "Utwórz zlecenie": "Create an order",
        "Uwaga": "Attention",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "NOTE: TypeScript is not supported and imports cannot be made in the method code.",
        "Uwagi": "Notes",
        "Uwagi do dokumentu wydania": "Notes on the issue document",
        "Uwagi do inwentaryzacji zbiorczej": "Notes on the collective inventory",
        "Uwagi do przesunięcia": "Comments on the move",
        "Uwagi dokumentu": "Comments of the document",
        "Uwagi:": "Comments:",
        "Uwzględnij wydziały podrzędne": "Include sub-departments",
        "Uzupełnij wagę": "Complete the weight",
        "Uzupełnij wymiary": "Complete the dimensions",
        "Użyj": "Use",
        "Użyte narzędzie": "Tool used",
        "Użytkownicy": "Users",
        "Użytkownicy w tej roli": "Users in this role",
        "Użytkownik": "User",
        "Użytkownik \"%0\" %1 obiekt %2": "User \"%0\" %1 obiect %2",
        "Użytkownik jest liderem zespołu": "The user is a team leader",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "The user cannot log in until the account is active.",
        "Użytkownik nie należy do żadnego zespołu.": "The user does not belong to any team.",
        "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
        "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
        "Użytkownik został dodany.": "The user has been added.",
        "Użytkownik został usunięty.": "User has been deleted.",
        "Użytkownik został wylogowany.": "The user has been logged out.",
        "Użytkownik został zaktualizowany.": "The user has been updated.",
        "Użyty Model": "Model Used",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "The following variables are available in the validation method body:",
        "W każdy dzień tygodnia": "On any day of the week",
        "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
        "W przygotowaniu": "In preparation",
        "W przyszłości": "In the future",
        "W trakcie": "In progress",
        "W trakcie zbierania": "In the process of collecting",
        "W zespole od %0": "In the team from %0",
        "W zespole od %0 do %1": "In the team from %0 do %1",
        "Waga": "Weight",
        "Waga (%0)": "Weight (%0)",
        "Waga całk.": "Overall weight.",
        "Waga została zaktualizowana.": "The weight has been updated.",
        "Walidacja": "Validation",
        "Walidacja certyfikatu SSL": "SSL certificate validation",
        "Walidacja danych": "Data validation",
        "Walidacja po stronie API": "Validation on the API side",
        "Walidacja umieszczania towarów na lokalizacji": "Validation of goods placement on location",
        "Wariant": "Variant",
        "Warn - ostrzeżenia": "Warn - warnings",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "Values ​​from 0 - 1.0 (0 means 0% and 1 means 100%)",
        "Wartości rzeczywiste": "Actual values",
        "Wartość": "Value",
        "Wartość domyślna": "Default value",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "Default value: (&(objectClass=organizationalUnit))",
        "Wartość domyślna: (&(objectClass=user)": "Default value: (&(objectClass=user)",
        "Wartość musi być liczbą całkowitą": "The value must be an integer",
        "Wartość musi być liczbą dodatnią": "The value must be a positive number",
        "Wartość musi być liczbą ujemną": "The value must be a negative number",
        "Wartość procentowej sprzedaży": "Sales percentage value",
        "Wartość: %0": "Value: %0",
        "Warunki korzystania z usług Google": "Google Terms of Service",
        "Warunki logiczne": "Logical conditions",
        "Warunkowo": "Conditionally",
        "Ważne informacje": "Important information",
        "Ważność": "Validity",
        "Wątek": "Thread",
        "Wątek główny": "Main thread",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "Wątek główny jest kontynuowany automatycznie przez asystenta.",
        "Webhook został dodany.": "The webhook has been added.",
        "Webhook został usunięty.": "The webhook has been removed.",
        "Webhook został zaktualizowany.": "The webhook has been updated.",
        "Weekend": "Weekend",
        "Wersja": "Version",
        "Wersja API": "API version",
        "Wersja utowrzona przez": "Version created by",
        "Wersje": "Versions",
        "Wersje dokumentu": "Document versions",
        "wersji zaakceptowanej": "accepted version",
        "Wersjonowanie dokumentów": "Versioning of documents",
        "Wersjonowanie wpisów": "Versioning of entries",
        "Wertykalna": "Vertical",
        "Wewnętrznie": "Internally",
        "Wiadomość testowa została wysłana.": "A test message has been sent.",
        "Wiadomość...": "Message...",
        "Widgety": "Widgets",
        "Widoczność": "Visibility",
        "Widoczność modułu w menu": "Module visibility in the menu",
        "Widoczność sekcji w menu modułu": "Widoczność sekcji w menu modułu",
        "Widok ustawień": "Settings view",
        "Wielkość kroku": "Step size",
        "Wielokrotny wybór": "Multiple choice",
        "Wiersz": "Row",
        "Więcej": "More",
        "więcej...": "more...",
        "Więcej...": "More…",
        "Wklej komponent": "Paste component",
        "Właściwości": "Characteristics",
        "Właściwości szablonu": "Template properties",
        "Włącz": "Turn on",
        "Włącz system powiadomień": "Enable the notification system",
        "Włącz walidację po stronie API": "Enable validation on the API side",
        "Włączenie moderacji danych wejściowych jest zalecane.": "Enabling input moderation is recommended.",
        "Włączone": "Enabled",
        "Włączony": "Activated",
        "WMS": "WMS",
        "Województwo": "Voivodeship",
        "Wpis domyślny": "Default entry",
        "Wpis został dodany.": "The post has been added.",
        "Wpis został usunięty.": "The post has been deleted.",
        "Wpis został zaakceptowany.": "The entry was accepted.",
        "Wpis został zaktualizowany.": "The post has been updated.",
        "Wpisz kod lokalizacji": "Enter the location code",
        "Wpisz wiadomość": "Enter your message",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "We enter the URL to the websites we want to exclude. Data can be separated by a comma or be on a new line",
        "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
        "Wprowadzona wartość jest za długa": "The value you entered is too long",
        "Wprowadzona wartość jest za krótka": "The value you entered is too short",
        "Wprowadzona wartość jest za niska": "The value you entered is too low",
        "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
        "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
        "Wróć do logowania": "Back to login",
        "Wróć do strony głównej": "Back to Home Page",
        "Wrzesień": "September",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "Indicates how long the response remains valid in Redis server memory.",
        "Współczynnik zmienności": "Coefficient of variation",
        "Wstaw": "Insert",
        "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
        "Wstaw dwie równe kolumny": "Insert two equal columns",
        "Wstaw kod kreskowy": "Insert barcode",
        "Wstaw kontrolkę": "Insert control",
        "Wstaw logo": "Insert logo",
        "Wstaw pole dokumentu": "Insert document field",
        "Wstaw stronę": "Insert page",
        "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
        "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
        "Wstaw z dołu": "Insert from below",
        "Wstaw z góry": "Insert from above",
        "Wstaw z lewej strony": "Insert left",
        "Wstaw z prawej strony": "Insert right",
        "Wstaw zdjęcie": "Insert photo",
        "Wstecz": "Back",
        "Wstępne filtrowanie danych": "Data pre-filtering",
        "Wszystkie": "All",
        "Wszystkie formularze": "All forms",
        "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
        "Wszystkie próby": "All attempts",
        "wybierz": "select",
        "Wybierz": "Select",
        "wybierz długość": "choose length",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "Select a form with additional fields for user accounts",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "Select the directory to which you want to move the selected items.",
        "wybierz model": "select model",
        "Wybierz plik": "Select a file",
        "Wybierz plik lub katalog": "Select a file or directory",
        "Wybierz plik.": "Choose a file.",
        "Wybierz pliki": "Select files",
        "Wybierz pliki (do {0})": "Select files (to {0})",
        "Wybierz rodzaj nośnika": "Select the carrier type",
        "Wybierz serwer": "Select server",
        "Wybierz shortcode": "Select a shortcode",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
        "Wybierz użytkownika": "Select a user",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "Select the users for whom you want to perform actions",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "Select the thread in which you want to conduct the conversation.",
        "Wybierz zaznaczone": "Choose selected",
        "wybierz...": "select...",
        "Wybierz...": "Select...",
        "Wybór": "Choice",
        "Wybór języka": "Language selection",
        "Wybór+": "Choice",
        "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
        "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
        "Wybrana drukarka: %0": "Selected printer: %0",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
        "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
        "Wybrane": "Selected",
        "Wybrane %0 na %1": "Selected %0 na %1",
        "Wybrane dokumenty": "Selected documents",
        "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
        "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
        "Wybrane pliki": "Selected files",
        "Wybrane pliki: {0}": "Selected files: {0}",
        "Wybrano %0 użytkowników.": "%0 users selected.",
        "Wybrano dostawcę Azure AI.": "Azure AI provider has been selected.",
        "Wybrano dostawcę Azure OpenAI.": "Azure OpenAI provider has been selected.",
        "Wybrano dostawcę OpenAI.": "OpenAI's vendor has been selected.",
        "Wybrano jednego użytkownika.": "One user was selected.",
        "Wybrany język": "Selected language",
        "Wybróbuj możliwości AI": "Try out the possibilities of AI",
        "Wyczyść": "Clear",
        "Wydaj": "Spend",
        "Wydania": "Issues",
        "Wydania (Akceptacje)": "Issues (Acceptances)",
        "Wydania (Zależy od aktywnego procesu)": "Issues (Depends on active process)",
        "Wydania bezpośrednie": "Direct issue",
        "Wydania do kuriera (Zależy od aktywnego procesu)": "Issue to courier (Depends on active process)",
        "Wydania do kurierów": "Issues to couriers",
        "Wydanie": "Issue",
        "Wydanie (inwentaryzacja)": "Issue (inventory)",
        "Wydanie bezpośrednie zostało dodane": "Direct issue has been added",
        "Wydruk": "Printout",
        "Wydruk etykiety": "Label printing",
        "Wydrukuj dokument": "Print the document",
        "Wydział": "Department",
        "Wygenerowana odpowiedź przez model AI.": "Generated response by AI model.",
        "Wygenerowane": "Generated",
        "Wygenerowane obrazy": "Generated images",
        "Wygenerowano dokument.": "Document generated.",
        "Wygeneruj metadane w wybranym języku dla przesyłanych obrazów": "Generate metadata in the language of your choice for uploaded images",
        "Wygeneruj zapytanie poniżej": "Generate your query below",
        "Wyjdź": "Exit",
        "Wyklucz logowanie zależności": "Exclude dependency logging",
        "Wykluczona": "Excluded",
        "Wykonaj": "Do",
        "Wykorzystane": "Used",
        "Wykres": "Chart",
        "Wylicz": "Calculate",
        "Wyliczenia XYZ": "XYZ calculations",
        "Wyloguj się": "Log out",
        "Wyloguj zalogowanego obecnie użytkownika": "Log out the currently logged in user",
        "Wyłaczone": "Off",
        "Wyłącz": "Turn off",
        "Wyłączone": "Disabled",
        "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
        "Wymagane": "Required",
        "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
        "Wymagane jest wybranie formularza": "It is required to select the form",
        "Wymagane jest wybranie kategorii": "It is required to select a category",
        "Wymagane jest wybranie komponentu": "Component selection is required",
        "Wymagane jest wybranie modułu": "Selecting a module is required",
        "Wymagane jest wybranie sekcji": "A section must be selected",
        "Wymagane jest wybranie źródła danych": "Data source is required",
        "Wymagania": "Requirements",
        "Wymiary": "Dimensions",
        "Wymiary zostały zaktualizowane.": "Dimensions have been updated.",
        "Wymuś zmianę hasła": "Force password change",
        "Wynik testu": "Test result",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "The test results will always be limited to a maximum of 10 items.",
        "Wyrażenia warunkowe": "Conditional expressions",
        "Wyrażenie regularne": "Regular expression",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "Expression in the form \"/pattern/flag\" is not supported, use pattern values alone",
        "Wysłane": "Sent",
        "Wysoka szczegółowość": "High detail",
        "Wysokość": "Height",
        "Wysokość (%0)": "Height (%0)",
        "Wysokość (mm)": "Height (mm)",
        "Wysokość [%0]": "Height [%0]",
        "Wysokość strony": "Page height",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "An error occurred while loading the image. Try again.",
        "Wystąpił błąd podczas próby exportu użytkowników.": "An error occurred while trying to export users.",
        "Wystąpił błąd podczas próby importu definicji modułu.": "An error occurred while trying to import a module definition.",
        "Wystąpił błąd podczas próby importu użytkowników.": "An error occurred while trying to import users.",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
        "Wystąpił błąd przy zapisywaniu stanowisk pakowaczy.": "There was an error saving packer positions.",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
        "Wystąpił problem drukowania": "There was a printing problem",
        "Wystąpił problem podczas przesyłania obrazu.": "There was a problem uploading the image.",
        "Wystąpił problem, spróbuj ponownie.": "There's a problem, please try again.",
        "Wysyłaj dane o użytkowniku": "Send user information",
        "Wysyłaj tym kanałem": "Send through this channel",
        "Wyszukaj": "Search",
        "Wyszukaj (Ctrl+Q)": "Search (Ctrl+Q)",
        "Wyszukaj drukarkę w sieci": "Search for a printer on the network",
        "wyszukaj użytkownika...": "search for user...",
        "Wyszukaj wszędzie": "Search everywhere",
        "wyszukaj...": "search...",
        "Wyszukaj...": "Search...",
        "Wyszukiwanie plików": "File search",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "File search allows the assistant to use knowledge from files. Once the file is uploaded, the assistant automatically decides when to download the content based on the user's requests.",
        "Wyszukiwany ciąg znaków": "Search set of characters",
        "Wyślij": "Send",
        "Wyślij do ERP": "Send to ERP",
        "Wyślij kod": "Send the code",
        "Wyświetl również zarchiwizowane formularze": "Display also archived forms",
        "Wyświetlam": "Displaying",
        "Wyświetlanie dokumentów": "Displaying documents",
        "Wyświetlany układ / dane": "Layout/data displayed",
        "Wytnij": "Cut",
        "Wyzeruj licznik": "Reset counter",
        "WZ – wydanie zewnętrzne": "WZ – external edition",
        "Wzorzec": "Pattern",
        "XYZ": "XYZ",
        "Z magazynu": "From the magazine",
        "Z uprawnieniami": "With permissions",
        "Z uprawnieniami do modułu": "With permission to module",
        "Z: %0": "From: %0",
        "Zaakceptowany": "Accepted",
        "Zaaktualizowano listę.": "Updated list.",
        "Zaawansowane uprawnienia": "Advanced permissions",
        "Zabierz dostęp": "Take away access",
        "Zablokowana": "Blocked",
        "Zablokowani użytkownicy": "Blocked users",
        "Zablokowany": "Blocked",
        "Zablokuj": "Block",
        "Zablokuj pole": "Block the field",
        "Zachowaj oryginalny format obrazów": "Keep the original format of the images",
        "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to start prompting.",
        "Zaczyna sie od": "It starts with",
        "Zadania w tle": "Background tasks",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "The task of rebuilding the indexes has begun.",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "The task of reindexing the data has begun.",
        "Zadanie zostało uruchomione.": "The task has started.",
        "Zadanie zostało włączone": "The task was included",
        "Zadanie zostało wyłączone": "The task was excluded",
        "Zagregowane": "Aggregated",
        "Zagrożenie": "Threat",
        "Zaimportowano definicję modułu.": "A module definition has been imported.",
        "Zaimportowano listę użytkowników.": "A list of users has been imported.",
        "Zainstalowane": "Installed",
        "Zainstalowano poprawnie": "Installed correctly",
        "Zainstaluj": "Install",
        "Zakładki": "Bookmarks",
        "Zakolejkowane": "Pending",
        "Zakończ": "Finish",
        "Zakończ edycje": "Finish your edits",
        "Zakończ inwentaryzację": "Complete the inventory",
        "Zakończ inwentaryzację cząstkową": "Complete the sub-inventory",
        "Zakończ kontrolę": "Finish the check",
        "Zakończ pakowanie": "Finish packing",
        "Zakończ przesunięcie": "Complete the transfer",
        "Zakończ przyjęcie": "End the receipt",
        "Zakończ sesję": "End the session",
        "Zakończ sesję aby wylogować użytkownika": "End session to log out user",
        "Zakończ wydanie": "Complete the issue",
        "Zakończ zbieranie": "Finish collecting",
        "Zakończ zwrot": "Complete the turnaround",
        "Zakończone": "Completed",
        "Zakres": "Scope",
        "Zakres czasu": "Time range",
        "Zaktualizuj formularz": "Update form",
        "Zależna od kontrolki": "Control dependent",
        "Zalogowano na stanowisko:": "Logged in to the station:",
        "Zalogowany": "Logged in",
        "Zaloguj się": "Sign In",
        "Zaloguj się na swoje konto": "Log in to your account",
        "ZALOGUJ ZA POMOCĄ LOGINU I HASŁA": "LOG IN WITH YOUR LOGIN AND PASSWORD",
        "ZALOGUJ ZA POMOCĄ PINU": "LOG IN WITH PIN",
        "Załaduj wszystkie": "Load all",
        "Załadunek": "Loading",
        "Załączniki": "Attachments",
        "Zamiany zostały zapisane.": "The changes have been saved.",
        "Zamknij": "Close",
        "Zamów": "Order",
        "Zamówienia": "Orders",
        "Zamówienie": "Order",
        "Zamówienie zostało złożone": "Order has been placed",
        "Zaokrąglenie": "Rounding",
        "Zapamiętaj logowanie": "Remember login",
        "Zapamiętane urządzenia": "Memorized devices",
        "Zapisano": "Saved",
        "Zapisano poprawnie": "Saved correctly",
        "Zapisano poprawnie etykietę.": "The label was written correctly.",
        "Zapisano zmiany.": "Changes saved.",
        "Zapisuj do celu": "Save to destination",
        "Zapisywanie obrazów zakończone.": "Saving images completed.",
        "Zapisz": "Save",
        "Zapisz filtr": "Save filter",
        "Zapisz i przekaż do realizacji": "Save and forward for implementation",
        "Zapisz i wróć": "Save and return",
        "Zapisz i zamknij": "Save and close",
        "Zapisz raport, aby nadać uprawnienia": "Save the report to grant permissions",
        "Zapisz układ": "Save the layout",
        "Zapisz ustawienia widgetów": "Save widget settings",
        "Zapisz wybrane": "Save selected",
        "Zapisz zmiany": "Save changes",
        "Zaplanowane": "Scheduled",
        "Zapomniałeś hasła?": "Forgot your password?",
        "Zapytanie SQL": "SQL Query",
        "Zarchiwizowane": "Archived",
        "Zarchiwizowany": "Archived",
        "Zarządzaj kolumnami": "Manage columns",
        "Zasięg": "Range",
        "Zastępstwo": "Add a substitution",
        "Zastępstwo wygasło.": "The substitution has expired.",
        "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
        "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
        "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
        "Zastępstwo zostało dodane.": "The substitution has been added.",
        "Zastępstwo zostało usunięte.": "The substitution has been removed.",
        "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
        "Zastępuje osobę": "Substituting",
        "Zastosuj zmiany w aplikacji": "Apply changes to the application",
        "Zatwierdź": "Approve",
        "Zatwierdź zmiany": "Commit changes",
        "Zawartość": "Content",
        "Zawiera": "Contains",
        "Zawiera listy przewozowe": "Includes waybills",
        "Zawsze": "Always",
        "Zaznacz": "Select",
        "Zaznacz wszystkie": "Select all",
        "Zbieraj towary": "Collect goods",
        "Zbieranie": "Collecting",
        "Zbieranie (Zależy od aktywnego procesu)": "Collection (Depends on active process)",
        "Zbierz towary": "Collect the goods",
        "Zbiorczo": "Collectively",
        "Zdarzenie": "Event",
        "Zdjęcie": "Photo",
        "Zdjęcie produktu": "Product photo",
        "Zdjęcie użytkownika": "User's photo",
        "Zebrane towary wg zamówień": "Collected goods by order",
        "Zeskanowano pomyślnie": "Scanned successfully",
        "Zeskanowano pomyślnie.": "Scanned successfully.",
        "Zeskanuj kod kreskowy": "Scan the barcode",
        "Zeskanuj kod opakowania": "Scan the packaging code",
        "Zeskanuj kod opakowania lub list przewozowy": "Scan the packing code or waybill",
        "Zeskanuj pozycje": "Scan items",
        "Zeskanuj pozycję": "Scan the position",
        "Zespoły": "Teams",
        "Zespół": "Team",
        "Zespół aktywny": "Active team",
        "Zespół został dodany.": "The team has been added.",
        "Zespół został usunięty.": "The team has been removed.",
        "Zespół został zaktualizowany.": "The team has been updated.",
        "Zewnętrzene linki": "External links",
        "Zewnętrzny dokument wydania": "External release document",
        "Zgoda": "Consent",
        "Zgodna": "Compatible",
        "Zlecenia": "Transfer orders",
        "Zlecenia przesunięć": "Transfer orders",
        "Zlecenie zostało przekazane do realizacji.": "The order has been forwarded for execution.",
        "Zlecenie zostało utworzone.": "The order was created.",
        "Zlecenie zostało zaktualizowane.": "The order has been updated.",
        "Zliczanie dokumentów i wyświetlanie wyników w menu": "Counting documents and displaying the results in the menu",
        "Zmiana hasła": "Password change",
        "Zmiana na %0.": "Change to %0.",
        "Zmiana rozmiaru obrazu": "Resize an image",
        "Zmiana z %0 na %1.": "Change from %0 na %1.",
        "Zmiana z %0.": "Changed from %0.",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "Changes to monitoring settings require restarting the application.",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "The changes will not take effect until the indexes are rebuilt.",
        "Zmiany zostały zapisane.": "Changes saved.",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "The changes will be applied to the application within a few seconds",
        "Zmieniono ustawienia widgetu": "Widget settings changed",
        "Zmień hasło": "Change password",
        "Zmień nazwę": "Rename",
        "Zmień nazwę katalogu": "Rename a directory",
        "Zmień nazwę pliku": "Rename a file",
        "Zmień rozmiar": "Resize",
        "Zmień status edytowanej wersji": "Change the status of the edited version",
        "Zmień widok": "Change view",
        "Zmniejsz": "Reduce",
        "zmodyfikował": "modified by",
        "Zmodyfikował": "Modified by",
        "zmodyfikowany": "modified",
        "znaki": "characters",
        "Zobacz sesję użytkownika": "View user session",
        "Zobacz szczegóły sesji użytkownika": "View user session details",
        "Zobacz szczegóły zdarzenia": "View event details",
        "Zobacz wszystkie": "See all",
        "Został przywrócony oryginalny harmonogram": "The original schedule has been restored",
        "Zresetuj hasło": "Reset password",
        "Zrestartuj aplikacje": "Restart the applications",
        "Zwolnij stanowisko": "Vacant your station",
        "Zwroty": "Returns",
        "Zwrócony": "Returned",
        "Zwróć do magazynu": "Return to warehouse",
        "Źródła LDAP": "LDAP sources",
        "Źródło": "Source",
        "Źródło aktywne": "Active source",
        "Źródło danych": "Data source",
        "źródło danych zostało dodane.": "the data source has been added.",
        "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
        "Źródło LDAP zostało dodane.": "LDAP source has been added.",
        "Źródło LDAP zostało zaktualizowane.": "The LDAP source has been updated.",
        "źródło zostało usunięte.": "the source has been removed.",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
        "Żródło danych": "Data source"
    }
};
