<template>
    <span>{{ item.result[configuration.field]?.name }}</span>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { BaseFormatterMixin } from '@/components/dynamicgird/mixins/BaseFormatterMixin';

@Options({})
export default class CourierFormatter extends mixins(BaseFormatterMixin)
{

}
</script>
