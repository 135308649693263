<script lang="ts" setup>
import { ref, computed } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

defineOptions({
    name: 'confirmation'
});

const props = withDefaults(defineProps<{
    value: number|Record<string, any> | null;
    message: string;
    wrapperClass?: Record<string, boolean> | string[] | string;
    actionClass?: Record<string, boolean> | string[] | string;
    icon?: string;
}>(), {
    wrapperClass: '',
    actionClass: '',
    icon: 'fa-light fa-circle-exclamation'
});

const emit = defineEmits<{
    (e: 'confirm', value: number|Record<string, any>): void,
    (e: 'cancel', value: number|Record<string, any>): void,
}>();

const modal = ref<typeof IdeoModal>(null);

const open = (): void =>
{
    modal.value.show();
};

const close = (): void =>
{
    modal.value.hide();
};

const confirm = (): void =>
{
    close();
    emit('confirm', props.value);
};

const cancel = (): void =>
{
    close();
    emit('cancel', props.value);
};

const wrapperClasses = computed(() => ({
    ...normalizeClasses(props.wrapperClass)
}));

const actionClasses = computed(() => ({
    'ms-auto': true,
    ...normalizeClasses(props.actionClass)
}));

defineExpose({
    open
});
</script>

<template>
    <ideo-modal ref="modal" class="confirmation-modal" hide-footer centered hide-header backdrop-class="confirmation-modal-index" modal-class="confirmation-modal-index">
        <div :class="wrapperClasses">
            <div class="d-flex justify-content-center mb-4">
                <div class="confirmation-modal__icon-container">
                    <i class="icon" :class="props.icon"></i>
                </div>
            </div>
            <div class="text-center">
                <h3 class="mb-4" v-html="message.replaceAll('\n', '<br>')"></h3>
                <div class="d-flex flex-column gap-4" :class="actionClasses">
                    <ideo-button variant="success" pill class="p-3 fs-3 w-100 m-0" @mousedown.stop.prevent="confirm">
                        {{ $t('[[[Tak]]]') }}
                    </ideo-button>
                    <ideo-button variant="outline-light" pill :no-border="true" class="p-3 fs-3 w-100 m-0" @mousedown.stop.prevent="cancel">
                        {{ $t('[[[Nie]]]') }}
                    </ideo-button>
                </div>
            </div>
        </div>
    </ideo-modal>
</template>

<style lang="scss">
.confirmation-modal {
    &__icon-container {
        .icon {
            padding: 1rem;
            border-radius: 50%;
            background-color: var(--confirmation-icon-bg);
            font-size: 2rem;
            color: var(--bs-primary);
        }
    }
}
</style>
