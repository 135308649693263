<script lang="ts" setup>
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';
import StarRating from '@/components/common/StarRating.vue';

defineOptions({
    name: 'PriorityFormatter',
    components: {
        StarRating
    }
});

interface Props
{
    configuration: DynamicGridColumn
    item: Resource<any>
}

const props = withDefaults(defineProps<Props>(), {});
</script>

<template>
    <template v-if="props.item.result[configuration.field]">
        <star-rating class="priority-wrapper" v-if="props.item.result[configuration.field].isSet" :max="props.item.result[configuration.field].priority" v-model="props.item.result[configuration.field].priority" disabled-click="true"></star-rating>
        <span v-else>-</span>
    </template>
</template>