<template>
    <div class="star-control">
        <div v-for="(item, index) in elements()" :key="index" class="star-control-item" :class="{ 'active': item.isSelected, 'nohover': disabledClick }">
            <i class="fa-solid fa-star me-1" @click="(!disabledClick) ? click(item.index + 1) : ''"></i>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

type StarModel = { index: number, isSelected: boolean};

@Options({
    name: 'star-rating'
})
export default class StarRating extends Vue
{
    @Prop({default: 5}) public max!: number;
    @Prop({default: 0}) public modelValue!: number;
    @Prop({default: false}) public disabledClick!: boolean;

    public elements() : StarModel[]
    {
        const result = [] as StarModel[];

        for (let i = 0; i < this.max; i++)
        {
            const element = { index: i, isSelected: i < this.modelValue };

            result.push(element);

        }

        return result;
    }

    @Emit('change', { multipleEventArgs: true })
    @Emit('update:modelValue')
    public click(value: number): number
    {
        return value;
    }
}
</script>

<style scoped lang="scss">

.star-control{
    display: flex;
}

.star-control-item:not(.nohover):has(~ .star-control-item:hover),
.star-control-item:not(.nohover):hover {
    cursor: pointer;

    i {
        color: rgb(247, 229, 63) !important;
    }
}

.star-control-item{
    &.active {
        i {
            color: orange;
        }
    }
}
</style>

