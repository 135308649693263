<template>
    <ideo-filter :name="name" :label="label" :display="filterDisplay" @clear="resetFilter">
        <div class="date-range d-flex flex-column flex-md-row gap-2">
            <ideo-form-group :label="$t('[[[Od]]]')" :nospace="true">
                <ideo-form-input :name="name" v-model="weightFromValue" type="number" min="0" v-focus></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Do]]]')" :nospace="true">
                <ideo-form-input :name="name" v-model="weightToValue" type="number" :min="weightFromValue" v-focus></ideo-form-input>
            </ideo-form-group>
        </div>
    </ideo-filter>
</template>

<script lang="ts" setup>
import { useWmsViewsSettingsStore } from "@/modules/wms/configuration/settings/store";
import { computed } from "vue";

const wmsViewsSettingsStore  = useWmsViewsSettingsStore();

interface Props
{
    weightFrom?: number
    weightTo?: number
    label?: string
    name: string
}

const props = withDefaults(defineProps<Props>(), {
    weightFrom: null,
    weightTo: null,
    label: '',
    name: ''
});

defineOptions({
    name: 'weight-range-filter',
    inheritAttrs: false,
});

const emit = defineEmits<{
    (e: 'update:weightFrom', value: number): void,
    (e: 'update:weightTo', value: number): void
}>();


const weightFromValue = computed({
    get()
    {
        return props.weightFrom;
    },
    set(value: number)
    {
        emit('update:weightFrom', value);
    }
});

const weightToValue = computed({
    get()
    {
        return props.weightTo;
    },
    set(value: number)
    {
        emit('update:weightTo', value);
    }
});

const filterDisplay = computed<string>(() =>
{
    if (!props.weightFrom && !props.weightTo)
        return '';

    return (props.weightFrom ?? "N/A") + " - " + (props.weightTo ?? "N/A") + " " + wmsViewsSettingsStore.globalConfig?.weightUnit;
});

const resetFilter = (): void =>
{
    weightFromValue.value = null;
    weightToValue.value = null;
};
</script>

