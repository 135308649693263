<script lang="ts" setup>
import { computed, onMounted, ref, watch, type ComputedRef} from 'vue';
import { handleException } from '@/helpers/Utils';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import IssueServices, { CollectingSplitConfigModel } from '@/modules/wms/issue/common/services/IssueServices';

interface AdditionalConfiguration {
    autocompleteType: string;
    type: string;
    value: string | null
    visibility?: string[]
    extraData: any
    fields: AdditionalConfiguration[]
    name: string
    setToField: string
}

interface Item {
    additionalConfiguration?: AdditionalConfiguration
    name: string;
    type: string;
    value: string | any[];
    values: any[];
    hidden: boolean,
    fields: AdditionalConfiguration[]
    setToField: string
}

interface Props {
    item: Item;
    mainItem: Item;
}

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const emit = defineEmits<{
    (e: 'update'): void
}>();

const props = withDefaults(defineProps<Props>(), {});
const computedItem: ComputedRef<Item> = computed(() =>
{
    return props.item;
});

const mainItem: ComputedRef<Item> = computed(() =>
{
    return props.mainItem;
});

const isOrderPartition = computed(() => computedItem.value.id == 'order_partition' );

const collectingSplitOptions = ref<CollectingSplitConfigModel[]>([]);

onMounted(() =>
{
    if (isOrderPartition.value) loadCollectingConfig();
});

const isFieldVisible = (field: AdditionalConfiguration): boolean =>
{
    if (!field.visibility || !field.visibility?.length) return false;

    return field.visibility.includes(mainItem.value.value?.toString());
};

const fieldComponent = (field: AdditionalConfiguration) : string =>
{
    if (field.autocompleteType)
    {
        switch (field.autocompleteType)
        {
            case "EndLocalizations":
                return "LocalizationPicker";
            case "Products":
                return "ProductsPicker";
            case "ProductFeature":
                return "ProductsFeaturesPicker";
            case "ContractorFeature":
                return "ContractorsFeaturesPicker";
            case "Zone":
                return "ZonesPicker";
        }
    }

    switch (field?.type)
    {
        case 'Text':
            return 'ideo-form-input';
        case 'Select':
            return 'ideo-multiselect';
        case 'Checkbox':
            return 'ideo-form-checkbox';
        case 'DocumentNumeration':
            return 'document-numeration';
        default:
            return 'ideo-form-input';
    }
};

const isMultiple = (field: AdditionalConfiguration) : boolean =>
{
    if (field.type == 'MultiselectAutocomplete')
        return true;

    return false;
};

const add = () : void  =>
{
    const object = {};

    const visibleFields = computedItem.value.fields.filter(field => isFieldVisible(field));

    for (let index = 0; index < visibleFields.length; index++)
    {
        const field = visibleFields[index];

        if (!field.value)
            return;

        object[field.setToField] = field.value;

        field.value = null;
    }

    if (!computedItem.value.value)
        computedItem.value.value = [];

    computedItem.value.value = [ ...computedItem.value.value, object];
};


const getObjectNameByKey = (key: string) : string =>
{
    const field = computedItem.value.fields.filter(field => isFieldVisible(field)).find(x => x.setToField == key);

    if (field)
        return field.name;

    return '';
};

async function loadCollectingConfig()
{
    try
    {
        const response = await IssueServices.getCollectingSplitConfig();

        collectingSplitOptions.value = response;
    }
    catch (ex)
    {
        collectingSplitOptions.value = [];
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

watch(() => props.item, () =>
{
    emit('update');
}, {deep: true});

const remove = (index: number) : void =>
{
    (computedItem.value.value as any[]).splice(index, 1);
};

</script>

<template>
    <div v-if="computedItem.value" class="mt-3">
        <template v-for="(value, index) in computedItem.value" :key="index">
            <div class="d-flex align-items-center">
                <template v-for="field in computedItem.fields" :key="field.name">
                    <template v-if="isFieldVisible(field)">
                        <ideo-form-group :label="index == 0 ? field.name : ''" class="me-2" :class="fieldComponent(field).includes('Picker') ? 'min-width-150' : ''">
                            <component
                                v-if="fieldComponent(field).includes('Picker')"
                                :is="fieldComponent(field)" v-model="value[field.setToField]"
                                :name="getObjectNameByKey(field.setToField)" :searchable="false" :show-reset="true" :disabled="true" :multiple="isMultiple(field)" :refresh="true"
                            />
                            <component
                                v-else-if="fieldComponent(field).includes('select')"
                                :is="fieldComponent(field)" v-model="value[field.setToField]"
                                :name="getObjectNameByKey(field.setToField)" :searchable="false" :show-reset="true" :disabled="true"
                            />
                            <component
                                v-else-if="fieldComponent(field).includes('document-numeration')"
                                :is="fieldComponent(field)" v-model="value[field.setToField]"
                                :name="getObjectNameByKey(field.setToField)" :options="field.extraData"
                            />
                            <component
                                v-else
                                :is="fieldComponent(field)" v-model="value[field.setToField]"
                                :name="getObjectNameByKey(field.setToField)"
                            />
                        </ideo-form-group>
                    </template>
                </template>
                <ideo-button variant="danger" :title="$t('[[[Dodaj]]]')" @click="remove(index)" :class="index == 0 ? 'mt-2' : 'mb-3'">
                    <i class="fa-solid fa-minus"></i>
                </ideo-button>
            </div>
        </template>
    </div>
    <template v-if="computedItem.fields">
        <div class="d-flex align-items-center">
            <template v-for="field in computedItem.fields" :key="field.id">
                <template v-if="isFieldVisible(field)">
                    <ideo-form-group :label="computedItem.value.length == 0 ? field.name : ''" class="me-2" :class="fieldComponent(field).includes('Picker') ? 'min-width-150' : ''">
                        <component
                            v-if=" fieldComponent(field).includes('select')"
                            :is="fieldComponent(field)" v-model="field.value"
                            :name="field.name" :searchable="false" :show-reset="true"
                        />
                        <component
                            v-else-if="fieldComponent(field).includes('Picker')"
                            :is="fieldComponent(field)" v-model="field.value"
                            :name="field.name" :searchable="false" :show-reset="true" :multiple="isMultiple(field)" :refresh="isMultiple(field)"
                        />
                        <component
                            v-else-if="fieldComponent(field).includes('document-numeration')"
                            :is="fieldComponent(field)" v-model="field.value"
                            :name="field.name" :options="field.extraData"
                        />
                        <component
                            v-else
                            :is="fieldComponent(field)" v-model="field.value"
                            :name="field.name"
                        />
                    </ideo-form-group>
                </template>
            </template>
            <ideo-form-group>
                <ideo-button variant="success" :title="$t('[[[Dodaj]]]')" @click="add()">
                    <i class="fa-solid fa-plus"></i>
                </ideo-button>
            </ideo-form-group>
        </div>
    </template>
</template>


<style lang="scss" scoped>
.min-width-150{
    min-width: 150px;
}
</style>
