<script lang="ts" setup>
import { computed, ref, nextTick, watch } from 'vue';
import { Activity, DirectLine, Message } from 'botframework-directlinejs';
import { marked } from 'marked';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useSpeechRecognition } from '@vueuse/core';
import { useAuthStore } from '@/store/auth';
import ChatbotService from '@/views/partials/chatbot/ChatbotService';
import { useWmsViewsSettingsStore } from '@/modules/wms/configuration/settings/store';

const wmsViewsSettingsStore = useWmsViewsSettingsStore();

enum RoleType {
    User = 'User',
    Bot = 'Bot'
}

interface MessageModel {
    content: string
    type: RoleType
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const authStore = useAuthStore();
const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();
const {
    isSupported,
    isListening,
    isFinal,
    result,
    start,
    stop,
} = useSpeechRecognition({
    lang: 'pl-PL',
    interimResults: true,
    continuous: true,
});

defineOptions({
    name: 'chatbot'
});

let directLine: DirectLine;
const query = ref('');
const conversationId = ref('');
const token = ref('');

const messages = ref<MessageModel[]>([]);
const input = ref<HTMLInputElement>(null);
const scrollElement = ref<HTMLElement>(null);
const scrollTimeoutId = ref(null);
const loading = ref(false);
const pollingIntervalId = ref(null);

const facePictureUrl = computed(() => authStore.identity?.facePicture ? authStore.identity?.facePicture.url : null);
const userName = computed(() => `${authStore.identity?.givenName} ${authStore.identity?.surname}`);
const userPublicId = computed<string>(() => authStore.identity?.publicId);

async function onSubmit(): Promise<void>
{
    if (!query.value.trim()) return;

    if (!messages.value.length) await initializeDirectLine();

    if (!token.value) return;

    loading.value = true;

    addMessage(query.value, RoleType.User);
    addMessage('loading', RoleType.Bot);
    scrollAndFocusChat();

    directLine
        .postActivity({
            from: { id: userPublicId.value, name: userName.value },
            type: 'message',
            text: query.value,
        })
        .subscribe(
            () =>
            {
                loading.value = false;
                scrollAndFocusChat();
            },
            (error) =>
            {
                loading.value = false;

                messages.value[messages.value.length - 1].content = 'Error';
                handleException($log, error, {
                    400: (error: any) => $alert.warning(error.message)
                });
            }
        );

    query.value = '';
}

async function initializeDirectLine(): Promise<void>
{
    if (!wmsViewsSettingsStore.chatbotSecretKey)
    {
        $alert.danger($t('[[[Brak klucza dostępu.]]]'));

        return;
    }

    try
    {
        const response = await ChatbotService.generateToken(wmsViewsSettingsStore.chatbotSecretKey);

        token.value = response.token;
        conversationId.value = response.conversationId;

        directLine = new DirectLine({ token: token.value });

        directLine.activity$
            .filter(activity => activity.type === 'message' && activity.from.id !== userPublicId.value)
            .subscribe((activity: Activity) =>
            {
                messages.value.pop();
                addMessage((activity as Message).text, RoleType.Bot);

                clearTimeout(scrollTimeoutId.value);
                scrollTimeoutId.value = setTimeout(() =>
                {
                    scrollAndFocusChat();
                }, 0);
            });
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message),
        });
    }
}

function addMessage(content: string, type: RoleType): void
{
    const message: MessageModel = {
        content,
        type
    };

    messages.value.push(message);
}

function scrollAndFocusChat(): void
{
    nextTick(() =>
    {
        input.value.focus();
        scrollElement.value.scrollTop = scrollElement.value.scrollHeight;
    });
}

function startPolling()
{
    let lastResult: string;

    pollingIntervalId.value = setInterval(() =>
    {
        if (lastResult === result.value && result.value) applySpeechResults();

        lastResult = result.value;
    }, 1000);
}

function stopPolling()
{
    if (pollingIntervalId.value)
    {
        clearInterval(pollingIntervalId.value);
        pollingIntervalId.value = null;
    }
}

function toggleSpeech()
{
    if (isListening.value)
    {
        if (isSafari) stopPolling();

        stop();
    }
    else
    {
        result.value = '';
        start();

        if (isSafari) startPolling();
    }
}

function markdown(text: string)
{
    return marked.parse(text);
}

function applySpeechResults()
{
    onSubmit();
    toggleSpeech();
}

watch(result, (value: string) =>
{
    if (!value || !isListening.value) return;

    query.value = value;
});

watch(isFinal, (newValue: boolean, oldValue: boolean) =>
{
    if (isSafari) return;

    if (!oldValue && newValue)
    {
        applySpeechResults();
    }
});

defineExpose({
    focus: () => input.value.focus()
});
</script>

<template>
    <div class="chatbot">
        <div class="chatbot__header">
            <i class="fa-solid fa-message-bot icon"></i>
            <span class="title">Chatbot</span>
        </div>
        <div class="chatbot__body">
            <div class="messages scroll" ref="scrollElement">
                <div class="messages-container">
                    <div class="message" v-for="(message, index) in messages" :key="index">
                        <div class="profile-picture">
                            <ideo-img v-if="message.type == RoleType.User" :src="$filters.image(facePictureUrl, 'ico')" rounded="circle" :blank="!facePictureUrl" width="30" height="30" blank-color="#777" :alt="$t('[[[Zdjęcie użytkownika]]]')" />
                            <div v-else-if="message.type == RoleType.Bot" class="chatbot-icon">
                                <i class="fa-solid fa-message-bot icon"></i>
                            </div>
                        </div>
                        <div class="text-content">
                            <div class="name">{{ message.type == RoleType.User ? userName : 'Chatbot' }}</div>
                            <div class="content">
                                <span v-if="message.content === 'loading'" class="loading-ellipsis"></span>
                                <span v-else-if="message.content === 'Error'" class="text-danger">{{ $t('[[[Błąd, proszę spróbować jeszcze raz.]]]') }}</span>
                                <span v-else v-html="markdown(message.content)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-wrapper" @submit.prevent="onSubmit">
                <input v-model="query" type="text" v-focus :disabled="loading || isListening" class="input" :class="{'active': isListening}" ref="input" @keydown.enter.stop="onSubmit" :placeholder="isListening ? $t('[[[Słucham...]]]') : $t('[[[W czym mogę Ci pomóc?]]]')" />
                <div class="buttons">
                    <button v-if="isSupported" type="button" class="microphone fs-4" :class="{'listening': isListening}" :title="$t('[[[Mów]]]')" @click.stop="toggleSpeech" :disabled="loading">
                        <i class="fa-solid fa-microphone"></i>
                    </button>
                    <button type="submit" :title="$t('[[[Wyślij]]]')" :disabled="loading">
                        <i class="fa-solid fa-paper-plane-top"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.chatbot {
    padding: 20px;
    width: 385px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100dvh - (var(--ideo-breadcrumbs-height) + var(--ideo-header-height) + (2 * var(--ideo-view-padding-size))));
    border-radius: 4px;
    background-color: rgba(var(--bs-body-bg-rgb), 1);

    [data-bs-theme="dark"] & {
        background-color: rgba(var(--bs-body-bg-rgb), 1)
    }

    &.in-aside {
        position: sticky;
        top: 20px;
    }

    &.in-view {
        padding: 0;
        width: 100%;
        height: calc(100dvh - (var(--ideo-breadcrumbs-height) + var(--ideo-header-height) + (2 * var(--ideo-view-padding-size))));

        .chatbot__body {
            padding: 10px;
        }

        [data-bs-theme="dark"] & {
            background-color: var(--wms-panel-bg);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        .icon {
            color: var(--wms-theme-color);
            font-size: 1.25rem;
        }

        .title {
            font-size: 1.125rem;
            color: var(--dark-font);
            font-weight: 600;
        }
    }

    &__body {
        flex: 1;
        overflow: hidden;
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid var(--wms-grey-light);
        background-color: var(--chatbot-body-bg);

        [data-bs-theme="dark"] & {
            border-color: var(--bs-border-color);
        }

        .messages {
            flex: 1;
            overflow-y: auto;

            .messages-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            .message {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                .chatbot-icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    border-radius: 50%;
                    background-color: var(--wms-theme-color);
                    font-size: .875rem;
                }

                .text-content {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    color: var(--dark-font);

                    .name {
                        font-weight: 600;
                        font-size: .875rem;
                        line-height: 1.25;
                    }

                    .content {
                        font-size: .75rem;
                        line-height: 1.5;
                    }
                }
            }
        }

        .input-wrapper {
            width: 100%;
            padding: 0 10px;
            width: 100%;
            min-height: 50px;
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: var(--wms-panel-bg);
            border-radius: 5px;
            border: 1px solid var(--wms-grey-light);

            [data-bs-theme="dark"] & {
                background-color: rgba(var(--bs-body-bg-rgb), 1);
                border-color: var(--bs-border-color);
            }

            .input {
                flex-grow: 1;
                min-width: 0;
                height: 100%;
                background-color: transparent;
                border: 0;
                outline: none;

                &:disabled {
                    opacity: 0.5;
                }

                &.active {
                    caret-color: var(--wms-theme-color);
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                gap: 10px;
                height: 100%;

                button {
                    height: 100%;
                    background-color: transparent;
                    border: 0;
                    color: var(--dark-font);
                    font-size: .75rem;

                    &:disabled {
                        opacity: 0.5;
                    }
                }

                .microphone {
                    position: relative;
                    height: 32px;
                    width: 32px;
                    min-height: 32px;
                    min-width: 32px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    transition: all 0.5s;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        width: inherit;
                        height: inherit;
                        border-radius: inherit;
                        background-color: inherit;
                        z-index: -1;
                    }

                    &.listening {
                        background-color: var(--wms-theme-color);
                        color: #fff;

                        &::before {
                            animation: listening 1.3s infinite;
                        }
                    }
                }
            }
        }
    }
}

@keyframes listening {
    from {
        opacity: 0.7;
    }
    to {
        transform: scale(1.8);
        opacity: 0;
    }
}
</style>
