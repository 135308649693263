<template>
    <ideo-filter :name="name" :label="label" :display="filterDisplay" @clear="resetFilter">
        <div class="date-range d-flex flex-column flex-md-row gap-2">
            <ideo-form-group :label="$t('[[[Od]]]')" :nospace="true">
                <ideo-form-input :name="name" v-model="fromValue" type="number" v-focus></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Do]]]')" :nospace="true">
                <ideo-form-input :name="name" v-model="toValue" type="number" :min="fromValue" v-focus></ideo-form-input>
            </ideo-form-group>
        </div>
    </ideo-filter>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props
{
    from?: number
    to?: number
    label?: string
    name: string
}

const props = withDefaults(defineProps<Props>(), {
    from: null,
    to: null,
    label: '',
    name: ''
});

defineOptions({
    name: 'numbers-range-filter',
    inheritAttrs: false,
});

const emit = defineEmits<{
    (e: 'update:from', value: number): void,
    (e: 'update:to', value: number): void
}>();


const fromValue = computed({
    get()
    {
        return props.from;
    },
    set(value: number)
    {
        emit('update:from', value);
    }
});

const toValue = computed({
    get()
    {
        return props.to;
    },
    set(value: number)
    {
        emit('update:to', value);
    }
});

const filterDisplay = computed<string>(() =>
{
    if (!props.from && !props.to)
        return '';

    return (props.from ?? "N/A") + " - " + (props.to ?? "N/A");
});

const resetFilter = (): void =>
{
    fromValue.value = null;
    toValue.value = null;
};
</script>

