import OneSignalVuePlugin from '@onesignal/onesignal-vue3';
import { Plugin } from 'vue';

export interface MyOneSignal {
    isEnabled : boolean
    token: string
}

const OneSignalPlugin: Plugin =
{
    install(app)
    {
        const vue = app.config.globalProperties;

        const myOneSignal = {
            isEnabled: false,
            token: null
        } as MyOneSignal;

        vue.$myOneSignal = myOneSignal;

        vue.$http.get('rest/onesignal/status').then((data: any) =>
        {
            const response = data.data;

            myOneSignal.isEnabled = response.token != null;
            myOneSignal.token = response.token;

            if (!myOneSignal.isEnabled)
                return;

            OneSignalVuePlugin.install(app, {
                appId: myOneSignal.token,
                allowLocalhostAsSecureOrigin: !import.meta.env.PROD,
            });
        });

    }
};

export default OneSignalPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $myOneSignal: MyOneSignal;
    }
}
