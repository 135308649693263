<script lang="ts" setup>
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount, ComputedRef } from 'vue';
import { useThemeStore } from '@/store/theme';
import { useMixins } from '@/plugins/mixins';
import { useAuth } from '@/plugins/auth';
import { $t, useLocalization } from '@/plugins/localization';
import Substitutions from '@/views/partials/layout/Substitutions.vue';
import IdeoDropdown from '@/components/ideo/dropdown/IdeoDropdown.vue';
import AuthService from '@/modules/core/auth/services/AuthService';
import { Settings } from 'luxon';
import IssueServices from '@/modules/wms/issue/common/services/IssueServices';

const { $auth } = useAuth();
const { $redirect } = useMixins();
const { $i18n } = useLocalization();

defineOptions({
    components: {
        'substitutions': Substitutions
    }
});

const menu = ref(null);
const visible = ref(false);

const facePictureUrl = computed(() =>
{
    const { url } = $auth.user()?.facePicture ?? {};

    return url || null;
});

const localesOptions: ComputedRef<Array<{ value: string; text: string }>> = computed(() =>
{
    return Object.entries($i18n.languages()).map(([key, value]) => ({ value: key, text: $t(value) }));
});

const useLangSwitch = computed(() => localesOptions.value.length === 2);

const langSwitchText = computed(() =>
{
    if (localesOptions.value.length < 2) return '';

    return `${getShortLocale(localesOptions.value[0].value)} → ${getShortLocale(localesOptions.value[1].value)}`;
});


const onClick = (): void =>
{
    menu.value.hide();
};

function getShortLocale(locale: string): string
{
    return locale.split('-')[0].toUpperCase();
}

onMounted(() =>
{
    window.addEventListener('click', onClick);
});

onBeforeUnmount(() =>
{
    window.removeEventListener('click', onClick);
});

const profile = (): void =>
{
    $redirect({ name: 'core-account-profile' });
    onClick();
};

const password = (): void =>
{
    $redirect({ name: 'core-account-password' });
    onClick();
};

const notifications = (): void =>
{
    $redirect({ name: 'core-account-notifications' });
    onClick();
};

const logout = async (): Promise<void> =>
{
    await tryReleasePackerStation();
    $redirect({ name: 'dashboard' });
    $auth.logout();
    onClick();
};

const tryReleasePackerStation = async () : Promise<void> =>
{
    try
    {
        const packerStation = await IssueServices.myPackerStation();

        if (packerStation.result?.publicId)
        {
            await IssueServices.releasePackerStation(packerStation.result.publicId);
        }
    }
    catch
    {
        // pomijamy, nie jest to bardzo istotne
    }
};

const onToggle = (): void =>
{
    visible.value = !visible.value;
};

const locale = computed({
    get(): string
    {
        return useThemeStore().locale;
    },
    set(value: string)
    {
        useThemeStore().locale = value;
    }
});


const onLangChange = async (value?: string): Promise<void> =>
{
    let newLocale = value ? value : locale.value;

    if (useLangSwitch.value) newLocale = locale.value === localesOptions.value[0].value ? localesOptions.value[1].value : localesOptions.value[0].value;

    if ($auth.check())
    {
        await AuthService.updatePreferredLanguage(newLocale);
    }

    $i18n.setLocale(newLocale);
    locale.value = newLocale;
    Settings.defaultLocale = locale.value;
};

onBeforeMount(async (): Promise<void> =>
{
    const { preferredLanguage } = $auth.user() ?? {};

    if (!locale.value)
        locale.value = preferredLanguage ?? $i18n.locale();

    if (locale.value && preferredLanguage && locale.value !== preferredLanguage)
        await onLangChange();
    else
    {
        $i18n.setLocale(locale.value);
        Settings.defaultLocale = locale.value;
    }
});
</script>

<template>
    <div id="account" class="d-flex align-self-center">
        <ideo-dropdown ref="menu" variant="link" :toggle-class="{'navbar-toggler': true, 'active': visible}" right no-caret @toggle="onToggle">
            <template #button-content>
                <ideo-img :src="$filters.image(facePictureUrl, 'ico')" rounded="circle" :blank="!facePictureUrl" blank-type="avatar" width="40" height="40" blank-color="#777" :alt="$t('[[[Zdjęcie użytkownika]]]')" />
            </template>

            <substitutions />

            <ideo-dropdown-item @click.prevent="profile()" :to="{ name: 'core-account-profile' }">
                <i class="icon far fa-user"></i> <span>{{ $t('[[[Profil użytkownika]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item @click.prevent="password()" :to="{ name: 'core-account-password' }">
                <i class="icon far fa-lock"></i> <span>{{ $t('[[[Zmiana hasła]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item @click.prevent="notifications()" :to="{ name: 'core-account-notifications' }">
                <i class="icon far fa-bell"></i> <span>{{ $t('[[[Powiadomienia]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item-button v-if="localesOptions.length > 1" @click.stop :class="['language', {'multiple': !useLangSwitch}]" prevent-close>
                <i class="icon far fa-language"></i>
                <span>
                    {{ $t('[[[Wybór języka]]]') }}:
                    <template v-if="useLangSwitch">{{ langSwitchText }}</template>
                </span>
                <ideo-form-checkbox v-if="useLangSwitch" class="mb-0 ms-auto" :switch="true" :checked="locale === localesOptions[1].value" @change="onLangChange" />
                <ideo-form-select v-else v-model="locale" :options="localesOptions" @update:modelValue="onLangChange"></ideo-form-select>
            </ideo-dropdown-item-button>
            <div class="dropdown-divider"></div>
            <ideo-dropdown-item @click.prevent="logout()" :to="{ name: 'dashboard' }">
                <i class="icon far fa-right-to-bracket"></i> <span>{{ $t('[[[Wyloguj się]]]') }}</span>
            </ideo-dropdown-item>
        </ideo-dropdown>
    </div>
</template>

<style lang="scss">
#account {
    .navbar-toggler {
        img {
            padding: 1px;
            border: 1px solid transparent;
        }

        &:hover img {
            border-color: var(--bs-primary);
        }
    }

    .dropdown {
        &-menu {
            z-index: var(--ideo-dropdown-zindex);
            min-width: 240px;
        }
        &-item {
            &.language {
                cursor: default;
                padding-right: 0.625rem;

                &:active, &:focus, &:hover {
                    background: inherit;
                    color: inherit;
                }

                &.multiple {
                    flex-wrap: wrap;
                }

                select, .select-container {
                    flex-basis: 100%;
                    width: 100%;
                }

                input[type="checkbox"] {
                    cursor: pointer;
                    width: 2rem;
                    height: 1rem;
                }
            }
        }
    }
}
</style>
