<template>
    <span v-if="value === null">{{ $t('[[[-]]]') }}</span>
    <span v-if="value === true">{{ $t('[[[Tak]]]') }}</span>
    <span v-if="value === false">{{ $t('[[[Nie]]]') }}</span>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';

@Options({})
export default class NullableBooleanFormatter extends Vue
{
    @Prop()
    public configuration: DynamicGridColumn;
    @Prop()
    public item: Resource<any>;

    public get value(): boolean | null
    {
        const tmp = this.item.result[this.configuration.field] as boolean | null;

        return tmp;
    }
}
</script>
