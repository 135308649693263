<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames" @click="onClick" @dblclick="onDblClick" v-if="visible">
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

export interface Props
{
    index: number;
    header: Header;
}

defineOptions({
    name: 'list-view-label',
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');
const item = inject<ComputedRef<any>>('item');
const rowClick = inject<(item: any, index: number) => void>('row-click');
const rowDblClick = inject<(item: any, index: number) => void>('row-dbl-click');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');
const classNames = computed(() => table.value ? 'pointer' : 'd-inline');
const visible = computed(() => props.header.visible);

const $attrs = useAttrs();
const minWidth = computed(() => parseInt($attrs.width as string) || 25);
const maxWidth = computed(() => parseInt($attrs.width as string) || 50);
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const onClick = (): void =>
{
    if (table.value && rowClick)
    {
        rowClick(item.value, props.index);
    }
};

const onDblClick = (): void =>
{
    if (table.value && rowDblClick)
    {
        rowDblClick(item.value, props.index);
    }
};
</script>
