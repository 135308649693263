<template>
    <template v-if="flag">
        <slot></slot>
    </template>
</template>

<script lang="ts" setup>
import { useLicences } from '@/plugins/licences';
import { computed, onMounted, ref } from 'vue';

interface Props {
    id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const { $licence } = useLicences();

const isLoaded = ref<boolean>(false);
const result = ref<boolean>(false);

const flag = computed(() =>
{
    if (!isLoaded.value)
        return false;

    if (isLoaded.value)
        return result.value;

    return false;
});

onMounted(async () =>
{
    await checkPermission();
});

const checkPermission = async () : Promise<void> =>
{
    if (!props.id)
        return;

    result.value = await $licence.check([props.id]);

    isLoaded.value = true;
};
</script>
