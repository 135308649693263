<template>
    <ideo-img class="img" :src="$filters.image(item.result[configuration.field], 'thumb')" width="70" height="70" blank-color="#777" v-if="item.result[configuration.field]" />
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';

@Options({})
export default class ImageFormatter extends Vue
{
    @Prop()
    public configuration: DynamicGridColumn;
    @Prop()
    public item: Resource<any>;
}
</script>

<style lang="scss" scoped>
    .img {
        object-fit: contain;
    }
</style>
