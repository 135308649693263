<template>
    <template v-if="configuration.withRedirect">
        <router-link :to="{name: configuration.route, params: params}">{{ $filters.default(value, '-') }}</router-link>
    </template>
    <span v-else>{{ $filters.default(value, '-') }}</span>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { BaseFormatterMixin } from '../../mixins/BaseFormatterMixin';

@Options({})
export default class NullableTextFormatter extends mixins(BaseFormatterMixin)
{
}
</script>
