import { axios } from '@/plugins/axios';


export default class Gs1Service
{
    public static async calculateCheckDigit(model: ValidateCodeModel): Promise<CalculateCheckDigitResult>
    {
        return (await axios.post<CalculateCheckDigitResult>('admin/gs1/calcualate-check-digit', model)).data;
    }

    public static async validateGtinCode(model: ValidateCodeModel): Promise<ValidateCodeResult>
    {
        return (await axios.post<ValidateCodeResult>('admin/gtin/validate-code', model)).data;
    }

    public static async validateGlnCode(model: ValidateCodeModel): Promise<ValidateCodeResult>
    {
        return (await axios.post<ValidateCodeResult>('admin/gln/validate-code', model)).data;
    }
}

export interface CalculateCheckDigitResult {
    result: number;
}

export interface ValidateCodeModel {
    code: string;
}

export interface ValidateCodeResult {
    anyError: boolean;
    notValidLength: boolean;
    emptyValue: boolean;
    withLetterOrSpecialCharacter: boolean;
    checkDigitIsDifferent: boolean;
    currentCheckDigit: number;
    calculatedCheckDigit: number;
}
