import GLightbox from 'glightbox';
import '~/glightbox/dist/css/glightbox.css';

interface useGallery
{
    openImage: (imageUrl: string) => void
}

export function useGallery(): useGallery
{
    const gallery = GLightbox({
        elements: [],
        touchNavigation: true,
        loop: true
    });

    const openImage = (imageUrl: string): void =>
    {
        if (!imageUrl) return;

        const imageArr = [{ href: imageUrl, type: 'image' }];

        gallery.setElements(imageArr);
        gallery.open();
    };

    return {
        openImage,
    };
}
