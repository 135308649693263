<template>
    <template v-if="item.result[configuration.field] && item.result[configuration.field].length > 0">
        <template v-if="mobile">
            <action-button variant="outline-secondary" icon="" class="mt-2" :text="$t('[[[Pokaż dostawy]]]')" @click.stop="showAll(item)" />
        </template>
        <template v-else>
            <div v-for="(deliveryInfo, index) in deliveryInfos" :key="index" class="mb-2">
                <div class="fw-bolder">{{ deliveryInfo.documentNumber }}</div>
                <div>{{ $filters.date(deliveryInfo.deliveryDateUtc) }} - {{ deliveryInfo.quantity.quantityAsStringWithUnit }}</div>
            </div>
            <action-button variant="outline-secondary" icon="" class="mt-2" :text="$t('[[[Pokaż wszystkie]]]')" @click.stop="showAll(item)" v-if="item.result.haveMoreDeliveryInfos" />
        </template>
        <NextDeliveryInfosModal ref="showAllModal" :product-id="selectedItemPublicId" />
    </template>
    <span v-else>
        -
    </span>
</template>

<script lang="ts" setup>
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';
import NextDeliveryInfosModal from '@/modules/wms/products/views/components/modals/NextDeliveryInfosModal.vue';
import { computed, ref } from 'vue';


interface Props
{
    configuration: DynamicGridColumn,
    item: Resource<any>
}

defineOptions({
    name: 'ProductDeliveryInfoArrayFormatter'
});

const props = withDefaults(defineProps<Props>(), {
    configuration: null,
    item: null
});

const showAllModal = ref(null);

const selectedItemPublicId = ref<string>('');

const deliveryInfos = computed(() =>
{
    return props.item.result[props.configuration.field];
});

const showAll = (item: Resource<any>) : void =>
{
    showAllModal.value.open(item.result.publicId);
};
</script>
