import { Plugin, getCurrentInstance, reactive } from 'vue';
import trim from 'lodash/trim';
import { RouteLocationNormalizedLoaded as Route } from 'vue-router';
import { Permissions } from '@/plugins/permissions';
import { WmsModules } from './wmsModules';
import { DocumentCountModel } from "@/modules/wms/common/services/ModulesService";
import { WmsModulesCounter } from '@/plugins/wmsModuleCounter';
import { LicenceHelper } from '@/plugins/licences';
import GlobalConfigurationService, { ConfigFormModel } from '@/modules/core/globalConfiguration/services/GlobalConfigurationService';

export interface Sitemap
{
    extraCrumb: string;
    append(name: string): void;
    purge(): void;
    all(): Promise<SitemapNode[]>;
    find(route: Partial<Route>): Promise<SitemapNode>;
    crumbs(route: Route): Promise<SitemapNode[]>;
    path(node: SitemapNode): SitemapNode[];
    active(node: SitemapNode, route: Route, recursive: boolean): boolean;
    url(node: SitemapNode, route: Route): any;
    reload(): Promise<void>;
    onReload(callback: () => Promise<void>): void;
}

export interface SitemapNode
{
    name: string;
    short?: string;
    route?: string;
    preventCloseMenu?: boolean;
    routeParams?: any;
    query?: object;
    url?: string;
    icon?: string;
    chevron?: string;
    namespace?: string;
    allowed?: boolean;
    event?: string,
    visible?: boolean;
    auth?: {
        all?: string[];
        any?: string[];
    };
    parent?: SitemapNode;
    children?: SitemapNode[];
    module?: string[];
    showCounterFromModules?: string[];
    counter?: {
        count?: number
    };
    globalConfigurationProperty?: string;
    provider?: string;
    clickable?: boolean
    visibleInBreadcrumbs?: boolean;
    licenceVisibility?: string[];
    licencePermissions?: string[];
    applicationAccess?: string[];
}

export class SitemapOptions
{
    public sitemap: SitemapNode[];
}

export class SitemapBuilder
{
    private permissions: Permissions = null;
    private sitemap: SitemapNode[] = null;
    private wmsModulesHelper: WmsModules = null;
    private wmsModulesCounter: WmsModulesCounter = null;
    private sitemapProviders: SitemapProvider[];
    private licenceHelper: LicenceHelper = null;


    public constructor(
        permissions: Permissions,
        options: SitemapOptions,
        wmsModules: WmsModules,
        modulesCounter: WmsModulesCounter,
        sitemapProviders: SitemapProvider[],
        licenceHelper: LicenceHelper)
    {
        this.permissions = permissions;
        this.sitemap = options.sitemap;
        this.wmsModulesHelper = wmsModules;
        this.wmsModulesCounter = modulesCounter;
        this.sitemapProviders = sitemapProviders;
        this.licenceHelper = licenceHelper;
    }

    public purge(): void
    {
        this.permissions.purge();
    }

    protected inspect(items: SitemapNode[], namespace: string = ''): Record<string, boolean>
    {
        let permissions: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const ns = item.namespace || namespace;

            if (item.auth && 'all' in item.auth)
            {
                (item.auth.all as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.auth && item.auth.any)
            {
                (item.auth.any as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.children)
            {
                permissions = Object.assign(permissions, this.inspect(item.children, ns));
            }
        }

        return permissions;
    }

    protected modules(items: SitemapNode[]): Record<string, boolean>
    {
        let modules: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const module = item.module == undefined ? [] : item.module;

            if (module)
            {
                for (let index = 0; index < module.length; index++)
                {
                    const element = module[index];

                    modules[element] = false;
                }
            }

            if (item.children)
            {
                modules = Object.assign(modules, this.modules(item.children));
            }
        }

        return modules;
    }

    protected visibilityFromLicence(items: SitemapNode[]): Record<string, boolean>
    {
        let modules: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const module = item.licenceVisibility == undefined ? [] : item.licenceVisibility;

            if (module)
            {
                for (let index = 0; index < module.length; index++)
                {
                    const element = module[index];

                    modules[element] = false;
                }
            }

            if (item.children)
            {
                modules = Object.assign(modules, this.visibilityFromLicence(item.children));
            }
        }

        return modules;
    }

    protected applicationAccessFromLicence(items: SitemapNode[]): Record<string, boolean>
    {
        let modules: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const module = item.applicationAccess == undefined ? [] : item.applicationAccess;

            if (module)
            {
                for (let index = 0; index < module.length; index++)
                {
                    const element = module[index];

                    modules[element] = false;
                }
            }

            if (item.children)
            {
                modules = Object.assign(modules, this.applicationAccessFromLicence(item.children));
            }
        }

        return modules;
    }

    protected async verify(permissions: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.permissions.get(Object.keys(permissions));
    }

    protected clone(items: SitemapNode[]): SitemapNode[]
    {
        return JSON.parse(JSON.stringify(items));
    }

    protected apply(
        items: SitemapNode[],
        permissions: Record<string, boolean>,
        modules: Record<string, boolean>,
        modulesCounter: DocumentCountModel[],
        globalConfiguration: ConfigFormModel,
        licenceVisibility: Record<string, boolean>,
        applicationAccess: Record<string, boolean>,
        namespace: string = ''): any[]
    {
        for (let i = 0; i < items.length; i++)
        {
            if  (items[i].provider)
            {
                const splitProvider = items[i].provider.split(':');
                const currentProvider = this.sitemapProviders.find(x => x.name == splitProvider[0]);
                const args = splitProvider.splice(1);

                items[i] = new SitemapNodeProvider(items[i], currentProvider, args);

            }

            const item = items[i];
            const ns = item.namespace || namespace;

            item.parent = item.parent || null;
            item.allowed = item.parent ? item.parent.allowed : true;
            item.visible = item.visible == undefined ? true : item.visible;
            item.children = item.children == undefined ? [] : item.children;
            item.module = item.module == undefined ? null : item.module;
            item.showCounterFromModules = item.showCounterFromModules == undefined ? null : item.showCounterFromModules;
            item.counter = item.counter == undefined ? {count: null} : item.counter;
            item.clickable = item.clickable == undefined ? true : item.clickable;
            item.visibleInBreadcrumbs = item.visibleInBreadcrumbs == undefined ? true : item.visibleInBreadcrumbs;
            item.licenceVisibility = item.licenceVisibility == undefined ? null : item.licenceVisibility;
            item.licencePermissions = item.licencePermissions == undefined ? null : item.licencePermissions;
            item.applicationAccess = item.applicationAccess == undefined ? null : item.applicationAccess;


            if (item.allowed && item.auth && item.auth.all && item.auth.all.length > 0)
            {
                item.allowed = this.all((item.auth.all as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.allowed && item.auth && item.auth.any && item.auth.any.length > 0)
            {
                item.allowed = this.any((item.auth.any as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.allowed && item.globalConfigurationProperty != undefined)
            {
                item.allowed = globalConfiguration[item.globalConfigurationProperty as keyof ConfigFormModel] === true;
            }

            if (item.children.length > 0)
            {
                item.children.forEach((p: any) => { p.parent = item; });
                this.apply(item.children, permissions, modules, modulesCounter, globalConfiguration, licenceVisibility, applicationAccess, ns);
            }

            item.visible = item.allowed && this.visible(item);

            if (item.showCounterFromModules)
            {
                modulesCounter && modulesCounter.forEach(element =>
                {
                    if (item.showCounterFromModules.includes(element.module))
                    {
                        if (item.counter.count == null)
                            item.counter.count = 0;

                        item.counter.count += element.count;
                    }
                });
            }

            if (item.module)
            {

                let canBeShow = false;

                for (let index = 0; index < item.module.length; index++)
                {
                    const element = item.module[index];

                    const check = modules[element];

                    if (check == true)
                        canBeShow = true;
                }

                if (!canBeShow)
                {
                    item.visible = false;
                }
            }

            if (item.licenceVisibility)
            {
                let canBeShow = false;

                for (let index = 0; index < item.licenceVisibility.length; index++)
                {
                    const element = item.licenceVisibility[index];

                    const check = licenceVisibility[element];

                    if (check == true)
                        canBeShow = true;
                }

                if (!canBeShow)
                {
                    item.visible = false;
                }
            }

            if (item.applicationAccess)
            {
                let canBeShow = false;

                for (let index = 0; index < item.applicationAccess.length; index++)
                {
                    const element = item.applicationAccess[index];

                    const check = applicationAccess[element];

                    if (check == true)
                        canBeShow = true;
                }

                if (!canBeShow)
                {
                    item.visible = false;
                }
            }
        }

        return items;
    }

    protected all(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.every(p => permissions[p] == true);
    }

    protected any(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.some(p => permissions[p] == true);
    }

    protected visible(node: SitemapNode): boolean
    {
        return node.clickable == false || (node.clickable && (node.route || node.url || node.provider)) ? node.visible : node.visible && this.visibleChildren(node);
    }

    protected visibleChildren(node: SitemapNode): boolean
    {
        return node.children && node.children.some((p: any) => this.visible(p));
    }

    protected async verifyModules(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.wmsModulesHelper.get(modules);
    }
    protected async setModulesCounters(): Promise<DocumentCountModel[]>
    {
        return await this.wmsModulesCounter.get();
    }

    protected async verifyLicenceVisibility(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.licenceHelper.get(modules);
    }

    protected async verifyApplicationAccess(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.licenceHelper.getApplicationAccess(modules);
    }

    public async build(): Promise<SitemapNode[]>
    {
        let permissions = this.inspect(this.sitemap);

        let modules = this.modules(this.sitemap);
        let licenceVisibility = this.visibilityFromLicence(this.sitemap);
        let applicationAccess = this.applicationAccessFromLicence(this.sitemap);

        permissions = await this.verify(permissions);

        modules = await this.verifyModules(modules);

        licenceVisibility = await this.verifyLicenceVisibility(licenceVisibility);
        applicationAccess = await this.verifyApplicationAccess(applicationAccess);

        await this.wmsModulesHelper.updateSitemap(this.sitemap);

        const modulesCounters = await this.setModulesCounters();
        const globalConfiguration = await GlobalConfigurationService.fetchConfig();

        let items = this.clone(this.sitemap);

        items = this.apply(items, permissions, modules, modulesCounters, globalConfiguration.result, licenceVisibility,applicationAccess);


        return items;
    }
}

interface SitemapProvider {
    get name(): string;
    get(args: string[]): Promise<SitemapNode[]>
}

class SitemapNodeProvider implements SitemapNode
{
    public name: string;
    public icon?: string;
    public namespace?: string;
    public allowed?: boolean;
    public visible?: boolean;
    public auth?: { all?: string[]; any?: string[]; };
    public parent?: SitemapNode;
    public children?: SitemapNode[];
    public provider?: string;

    private providerInstance: SitemapProvider;
    private providerArgs: string[];

    public constructor(node: SitemapNode, providerInstance: SitemapProvider, providerArgs: string[])
    {
        this.name = node.name;
        this.icon = node.icon;
        this.namespace = node.namespace;
        this.allowed = node.allowed;
        this.visible = node.visible;
        this.auth = node.auth;
        this.parent = node.parent;
        this.children = [];
        this.provider = node.provider;

        this.providerInstance = providerInstance;
        this.providerArgs = providerArgs;

        setTimeout(() => this.loadData(), 1000);

        if ((node as any).refreshTimeInSec)
        {
            setInterval(() => this.refresh(), (node as any).refreshTimeInSec * 1000);
        }
    }

    private async loadData() : Promise<void>
    {
        const children = await this.providerInstance.get(this.providerArgs);

        this.children = children;
    }

    private async refresh(): Promise<void>
    {
        await this.loadData();
    }
}

class SitemapHelper implements Sitemap
{
    private reactiveData = reactive({ extraCrumb: '' });
    private builder: SitemapBuilder;
    private sitemap: Promise<SitemapNode[]>;
    private callback: () => Promise<void>;

    public constructor(builder: SitemapBuilder)
    {
        this.builder = builder;
    }

    public get extraCrumb(): string
    {
        return this.reactiveData.extraCrumb;
    }

    public set extraCrumb(value: string)
    {
        this.reactiveData.extraCrumb = value;
    }

    public append(name: string): void
    {
        this.extraCrumb = name;
    }

    public purge(): void
    {
        this.builder.purge();
        this.sitemap = null;
    }

    public async all(): Promise<SitemapNode[]>
    {
        if (!this.sitemap)
        {
            this.sitemap = this.builder.build();
        }

        return await this.sitemap;
    }

    public async find(route: Partial<Route>): Promise<SitemapNode>
    {
        const find = (nodes: SitemapNode[], route: Partial<Route>): SitemapNode =>
        {
            let result: SitemapNode = null;

            for (let i = 0; i < nodes.length; i++)
            {
                if (this.active(nodes[i], route))
                {
                    result = nodes[i];
                }
                else if (nodes[i].children)
                {
                    result = result || find(nodes[i].children, route);
                }

                if (result != null)
                {
                    break;
                }
            }

            return result;
        };

        return find(await this.all(), route);
    }

    public async crumbs(route: Route): Promise<SitemapNode[]>
    {
        const node = await this.find(route);

        return this.path(node).filter((item: SitemapNode) => item.visibleInBreadcrumbs);
    }

    public path(node: SitemapNode): SitemapNode[]
    {
        const nodes: SitemapNode[] = [];

        while (node)
        {
            nodes.push(node);
            node = node.parent;
        }

        return nodes.reverse();
    }

    public active(node: SitemapNode, route: Partial<Route>, recursive: boolean = false): boolean
    {
        if (route.fullPath && node.url && route.fullPath === node.url)
        {
            return true;
        }
        else if (route.name && node.route && node.route === route.name)
        {
            if (node.routeParams && route.params)
            {
                return Object.keys(node.routeParams).every(key =>
                {
                    return key in route.params && JSON.stringify(node.routeParams[key]) == JSON.stringify(route.params[key]);
                });
            }

            return true;
        }
        else if (recursive == true && node.children.length > 0)
        {
            for (let i = 0; i < node.children.length; i++)
            {
                if (this.active(node.children[i], route, recursive))
                {
                    return true;
                }
            }
        }

        return false;
    }

    public url(node: SitemapNode, route: Route): any
    {
        if (node.visible && node.allowed)
        {
            if (node.route)
            {
                return { name: node.route, params: node.routeParams || {}, query: node.query || {} };
            }
            else if (node.url)
            {
                return node.url;
            }
        }

        return route.fullPath;
    }

    public async reload(): Promise<void>
    {
        if (this.callback)
        {
            await this.callback();
        }
    }

    public onReload(callback: () => Promise<void>): void
    {
        this.callback = callback;
    }
}

export const useSitemap = () =>
{
    const app = getCurrentInstance();

    return {
        $sitemap: app.appContext.config.globalProperties.$sitemap
    };
};

class ApiSitempaProvider implements SitemapProvider
{
    public get name(): string
    {
        return "api-sitemap-provider";
    }

    public async get(args: string[]): Promise<SitemapNode[]>
    {
        const result = [] as SitemapNode[];

        for (let index = 0; index < 5; index++)
        {
            const element = {
                name: 'Test ' + index + " | " + args[0],
                allowed: true,
                parent: this,
                visible: true,
                children: []
            } as SitemapNode;

            result.push(element);
        }

        return result;
    }

}

const SitemapPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        if (!vue.$permissions)
        {
            throw new Error("Vue:permissions must be set.");
        }

        if (!options || !options.sitemap)
        {
            throw new Error("SitemapOptions.sitemap must be set.");
        }

        const builder = new SitemapBuilder(vue.$permissions, options, vue.$wmsmodules, vue.$wmsmodulescounter, [ new ApiSitempaProvider() ], vue.$licence);

        vue.$sitemap = new SitemapHelper(builder);
    }
};

export default SitemapPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $sitemap: Sitemap;
    }
}
