<template>
    <div v-if="props.glnValidationEnabled && props.glnValidationResult">
        <span class="text-danger mt-2" v-if="props.glnValidationResult.notValidLength">{{ $t(`[[[Nieprawidłowa długość kodu dla GLN]]]`) }}</span>
        <span class="text-danger mt-2" v-if="props.glnValidationResult.withLetterOrSpecialCharacter">{{ $t(`[[[Kod GLN musi zawierać tylko cyfry]]]`) }}</span>
        <div class="text-danger mt-2" v-if="props.glnValidationResult.checkDigitIsDifferent">
            <span>{{ $t(`[[[Niewłaściwa cyfra kontrolna! Cyfra kontrolna dla lokalizacji to: %0|||${props.glnValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
        <div v-else-if="!props.glnValidationResult.anyError" class="text-danger mt-2">
            <span>{{ $t(`[[[Cyfra kontrolna: %0|||${props.glnValidationResult.calculatedCheckDigit}]]]`) }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ValidateCodeResult } from '@/modules/wms/common/services/Gs1Service';

interface Props {
    glnValidationEnabled: boolean;
    glnValidationResult: ValidateCodeResult | null;
}

const props = withDefaults(defineProps<Props>(), {});

</script>
