<script lang="ts" setup>
import { computed } from 'vue';
import { LOGO, useThemeStore } from '@/store/theme';
import Locales from '@/views/partials/layout/Locales.vue';
import { useLicencesStore } from '@/store/licenses';
import { AppAccessEnum } from '@/store/licenses';

const themeStore = useThemeStore();
const licensesStore = useLicencesStore();
const logo = computed(() => LOGO[licensesStore.appAccess][themeStore.theme].default);
const year = computed(() => (new Date()).getFullYear());
const changeTheme = (): void =>
{
    themeStore.changeTheme();
};
</script>

<template>
    <div class="auth-layout d-flex" :class="{'align-items-start': phone}">
        <div class="login-logo"></div>
        <div class="position-relative d-flex align-items-center justify-content-center bg-body" :class="{'w-100 rounded-bottom-4 py-5': phone, 'rounded-end-4 px-5': !phone}">
            <button type="button" class="navbar-toggler position-absolute top-0 end-0 mt-5 me-5 p-3" @click="changeTheme">
                <i class="far fa-circle-half-stroke"></i>
            </button>
            <div class="position-absolute top-0 start-0 mt-4 ms-2 ms-md-5 p-4">
                <Locales />
            </div>
            <div class="wrapper d-flex flex-column">
                <div class="text-center my-4 pt-5 mt-md-0 mb-2">
                    <img :src="logo" class="logo-img" :class="{'default-logo': licensesStore.appAccess == AppAccessEnum.default}">
                </div>
                <router-view />
                <p class="copy text-center my-4">
                    &copy; {{ year }} - Asiston CORE
                </p>
            </div>
            <portal-target name="auth-layout-bottom" />
        </div>
    </div>
</template>

<style lang="scss">
.auth-layout {
    min-height: 100vh;
    background: url(/static/img/bg.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .wrapper {
        width: 500px;

        .card {
            border-color: transparent;
        }

        .copy {
            color: var(--bs-body-color);
        }
    }
}

.logo-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 250px;

    &.default-logo {
        max-width: 350px;
    }
}
</style>
