<template>
    <div style="position: relative;">
        <ideo-dropdown
            :text="$t('[[[Zarządzaj kolumnami]]]')"
            :dropleft="!mobile"
            variant="dark"
            boundry="body"
            no-flip
            no-caret
            :top-offset="5"
            :menu-class="['pt-0', 'filter-dropdown-object', 'manager-dropdown']"
            ref="more-columns"
        >
            <template #button-content>
                <span class="d-flex align-items-center">
                    <span class="far fa-cog" style="font-size: 1.05rem;"></span>
                </span>
            </template>
            <ideo-tabs nav-class="flex-nowrap nav-justified" content-class="my-1">
                <ideo-tab :title="$t('[[[Kolumny]]]')" active>
                    <div class="columns-customizer">
                        <draggable
                            v-model="columns"
                            class="drop-zone"
                            :component-data="{tag: 'ul', type: 'transition-group', name: !drag ? 'flip-list' : null}"
                            @start="drag=true"
                            @end="drag=false"
                            @change="handleOrderChange"
                            ghost-class="ghost"
                            :item-key="(element) => `${element.field}`"
                        >
                            <template #item="{element}">
                                <ideo-dropdown-item-button style="cursor: move" prevent-close>
                                    <div class="d-flex align-items-center">
                                        <span class="far fa-grip-vertical mr-2" style="margin-right: 0.5rem;"></span>
                                        <ideo-form-checkbox class="customizer-checkbox" :modelValue="element.isActive" :disabled="isColumnCheckboxDisabled && element.isActive" @change="columnVisibilityChanged($event, element)">
                                            {{ $t(element.label) }} <template v-if="element.description"> - {{ $t(element.description) }}</template>
                                        </ideo-form-checkbox>
                                    </div>
                                </ideo-dropdown-item-button>
                            </template>
                        </draggable>
                    </div>
                </ideo-tab>
            </ideo-tabs>
            <!-- <ideo-button-group class="border-top px-3 pt-2 pb-0 d-flex flex-column flex-sm-row">
                <ideo-button variant="success" class="mr-2 text-nowrap rounded" @click.stop="saveView">
                    {{ $t("[[[Zapisz układ]]]") }}
                </ideo-button>
                <ideo-button variant="text" class="text-nowrap" @click.stop="resetView">
                    <i class="fal fa-times mr-1"></i>
                    {{ $t("[[[Resetuj układ]]]") }}
                </ideo-button>
            </ideo-button-group> -->
        </ideo-dropdown>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { DynamicGridColumn } from '@/components/dynamicgird/services/DynamicGridService';
import draggable from 'vuedraggable';

defineOptions({
    name: 'Customizer',
    components: {
        draggable,
    }
});

const columns = defineModel<DynamicGridColumn[]>('columns');

const emit = defineEmits<{
    (e: 'save'): void
}>();

const { $t } = useLocalization();
const { $alert } = useAlerts();

const isColumnCheckboxDisabled = computed<boolean>(() =>
{
    const visibleColumns = columns.value.filter(column =>
    {
        return column.isActive;
    });

    return visibleColumns.length <= 1;
});
const drag = ref(false);

function handleOrderChange()
{
    columns.value.forEach((element, index) =>
    {
        element.order = index + 1;
    });

    save();
}

function columnVisibilityChanged(value: boolean, item: DynamicGridColumn)
{
    const visibleColumns = columns.value.filter(column =>
    {
        return column.isActive;
    });

    if (!value && visibleColumns.length === 1)
    {
        $alert.warning($t("[[[Minimum jedna kolumna musi pozostać widoczna]]]"));
    }
    else
    {
        item.isActive = value;

        save();
    }
}

function save()
{
    emit('save');
}
</script>

<style lang="scss" scoped>
.order-switcher{
    color: var(--filter-nav-link-color);
}

button[disabled]{
    opacity: 0.6;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    border-top: 3px solid red;
}
</style>
